<template>
    <div>
        <div class="d-flex justify-center">
            <div class="home-public__body animation--opacity">
                <OHeaderViewComp
                    src="/imgs/parkAndCourt/icon.svg"
                    :title="$t('dataPark.headerInfo.title')"
                    :subtitle="$t('dataPark.headerInfo.subtitle')"
                />
                <OCourseInformationComp
                    class="space-top"
                    :withLogo="true"
                    :listInfo="$t('dataPark.listInfo')"
                    src="/imgs/parkAndCourt/park_and_court.png"
                />
                <OMessageComp
                    :title="$t('dataPark.titleImgs')"
                    class="space-top"
                    srcIcon="/imgs/parkAndCourt/park_icon.png"
                />
                <div class="d-flex justify-center flex-wrap space-bottom">
                    <OImgCircleComp
                        class="ma-2 mt-4"
                        v-for="(infoImg, index) in $t('dataPark.listImgs')"
                        :key="index"
                        :infoImg="infoImg"
                    />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {
    OHeaderViewComp,
    OCourseInformationComp,
    OImgCircleComp,
    OMessageComp
} from '../../components/organisms'
export default {
    components: {
        OHeaderViewComp,
        OCourseInformationComp,
        OImgCircleComp,
        OMessageComp
    }
}
</script>