import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import '@mdi/font/css/materialdesignicons.css';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
      options: {
        customProperties: true,
      },
    themes: {
      light: {
        //primary: '#007BFF',
        //secondary: '#424242',
        primary: '#39BCB8',
        secondary: '#E073C4',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107'
      },
    },
    icons: {
      iconfont: 'mdi', // default - only for display purposes
    }
  },
});
