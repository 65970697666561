<template>
    <div class="d-flex align-center justify-center animation--opacity">
        <h1 class="text-body-title mr-3">
        {{smsLoaderData}}</h1>
        <v-progress-linear
            style="width:100px"
            color="black"
            buffer-value="0"
            reverse
            stream
        ></v-progress-linear>
    </div>
</template>
<script>
export default {
    props: {
        smsLoaderData: String
    }
}
</script>