<template>
    <div class="s-description-about">
        <div class="s-description-about__mission">
            <OHeaderAboutComp
                :title="titleOne"
            />
            <div class="s-description-about__img">
                <v-img
                    :src="srcOne"
                    width="100%"
                    height="100%"
                ></v-img>
            </div>
            <div class="s-description-about__info">
                <h1 class="text-subtitle">
                    {{textOne}}
                </h1>
            </div>
        </div>
        <div class="s-description-about__vision">
            <OHeaderAboutComp
                :title="titleTwo"
            />
            <div class="s-description-about__img">
                <v-img
                    :src="srcTwo"
                    width="100%"
                    height="100%"
                ></v-img>
            </div>
            <div class="s-description-about__info">
                <h1 class="text-subtitle">
                    {{textTwo}}
                </h1>
            </div>
        </div>
    </div>
</template>
<script>
import { OHeaderAboutComp } from '../organisms'
export default {
    props: {
        titleOne: String,
        textOne: String,
        srcOne: String,
        titleTwo: String,
        textTwo: String,
        srcTwo: String
    },
    components: {
        OHeaderAboutComp
    }
}
</script>