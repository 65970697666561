import axios from 'axios'
import { apiUrls } from '../plugins/apiUrls'
import { headerAxiosStore } from '../store/modules'
export default class User{
    constructor
    (
        username = null,
        password = null,
        repitPassword = null,
    ){
        this.username = username
        this.password = password
        this.repitPassword = repitPassword
    }
    static authenticate(account){
        return axios.post(
            apiUrls.USERS + '/login',
            account
        ).then(
            response => {
                return response.data
        })
    }
    static closedSession() {
        return axios.post(
            apiUrls.USERS + '/logout',
            '',
            { headers: headerAxiosStore.state.header }
        ).then(
            response => {
                return response.data
            }
        )
    }
    static getAccount() {
        return axios.get(
            apiUrls.USERS + '/account',
            { headers: headerAxiosStore.state.header }
        ).then(
            response => {
                return response.data
            }
        )
    }
    static updateUser(parameters) {
        return axios.put(
            apiUrls.USERS + '/update',
            parameters,
            { headers: headerAxiosStore.state.header }
        ).then(
            response => {
                return response.data
            }
        )
    }
}