<template>
    <v-btn
        :outlined="outlined"
        :loading="loading"
        :depressed="depressed"
        small
        :ripple="false"
        :color="color"
        :dark="dark"
        @click="$emit('click')"
        :to="to"
    >
        <slot></slot>
    </v-btn>
</template>
<script>
export default {
    props: {
        text: String,
        outlined: Boolean,
        loading: Boolean,
        depressed: Boolean,
        color: {
            type: String,
            default: 'primary'
        },
        to: [String, Object],
        dark: Boolean
    }
}
</script>