<template>
    <div class="d-flex justify-center">
        <div class="home-public__body animation--opacity">
            <OTitleGalleryComp
                src="/imgs/nest/nest.svg"
                :title="$t('dataPublic.contact')"
            />
            <div class="d-flex justify-center flex-wrap contact__container space-bottom">
                <div class="contact__google-map">
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d568.5273229251442!2d-68.0734973090962!3d-16.547252621125143!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x915f219c89ccba11%3A0x9c7e57ca843b92cf!2sSweet%20Home%20Jard%C3%ADn%20Infantil!5e0!3m2!1ses!2sbo!4v1653056663866!5m2!1ses!2sbo"
                        :width="widthFrame"
                        :height="heightFrame"
                        style="border:0;"
                        allowfullscreen=""
                        loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade"
                    ></iframe>
                </div>
                <div class="contact__info">
                    <div class="d-flex">
                        <h1 class="text-title">
                            {{$t('dataPublic.location')}}:
                        </h1>
                        <h1 class="text-subtitle ml-1">La Paz - Bolivia</h1>
                    </div>
                    <div class="d-flex">
                        <h1 class="text-title">
                            {{$t('dataPublic.address')}}:
                        </h1>
                        <h1 class="text-subtitle ml-1">Av. Costanera #50, esq. calle 5</h1>
                    </div>
                    <div class="d-flex contact__info-text">
                        <a href="mailto:raunak.00o201@gmail.com" class="d-flex">
                            <h1 class="text-title">G-mail:</h1>
                            <h1 class="text-subtitle ml-1">sweethomebolivia80@gmail.com</h1>
                        </a>
                    </div>
                    <div class="d-flex contact__info-text" @click="goFace()">
                        <h1 class="text-title">Facebook:</h1>
                        <h1 class="text-subtitle ml-1">jardininfantilsweethomelp</h1>
                    </div>
                    <div class="contact__info-schedule">
                        <h1 class="text-title-inverse text-center">
                            {{$t('dataPublic.schedule')}}
                        </h1>
                        <div class="contact__info-ornament"></div>
                        <h1 class="text-subtitle-inverse text-center"
                            v-for="day in listSchedule"
                            :key="day.id"
                        >{{$i18n.locale!='es' ? day.day_en+': '+day.schedule : day.day_es+': '+day.schedule}}</h1>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { OTitleGalleryComp } from '../../components/organisms'
import { mapState } from 'vuex'
export default {
    components: {
        OTitleGalleryComp
    },
    computed: {
        ...mapState('scheduleStore', ['listSchedule'])
    },
    data: () => ({
        widthFrame: '500',
        heightFrame: '400'
    }),
    methods: {
        goFace() {
            window.open('https://www.facebook.com/jardininfantilsweethomelp/')
        },
        goMail() {
            window.open('mailto:sweethomebolivia80@gmail.com')
        }
    },
    mounted() {
        if (window.innerWidth <= 600) {
            this.widthFrame = '360';
            this.heightFrame = '300'
        }
    }
}
</script>