<template>
    <div @click="$emit('click')" style="cursor:pointer" class="pa-2" v-if="snakCorrecto">
        <v-snackbar
            :value="snakCorrecto"
            :multi-line="multiLine"
            right
            :bottom="true"
            :timeout="timeout"
            :color="colorAction"
        >
            <div class="d-block">
                <h1 style="color:white" class="text-body-subtitle-inverse" v-for="(tex, index) in smsAction" :key="index">{{tex}}</h1>
            </div>
        </v-snackbar>
    </div>
</template>
<script>
export default {
    props: {
        timeout: Number,
        snakCorrecto: Boolean,
        multiLine: Boolean,
        smsAction: Array,
        colorAction: {
            default: 'success',
            type: String
        }
    }
}
</script>
