<template>
    <div>
        <div class="d-flex justify-center">
            <div class="home-public__body animation--opacity">
                <OHeaderViewComp
                    src="/imgs/bilingualism/bilingual.svg"
                    :title="$t('dataBilingualism.headerInfo.title')"
                    :subtitle="$t('dataBilingualism.headerInfo.subtitle')"
                />
                <OCourseInformationComp
                    class="space-top"
                    :withLogo="true"
                    :listInfo="$t('dataBilingualism.listInfo')"
                    src="/imgs/bilingualism/bilingualism_fond.png"
                />
                <OMessageComp
                    :title="$t('dataBilingualism.messageInfo.title')"
                    class="space-top"
                    srcIcon="/imgs/bilingualism/icon_b.png"
                />
                <SListInformation
                    class="space-bottom"
                    :benefitsList="$t('dataBilingualism.benefitsList')"
                    srcImg="/imgs/bilingualism/bilingualism_2.jpg"
                />
            </div>
        </div>
    </div>
</template>
<script>
import {
    OHeaderViewComp,
    OCourseInformationComp,
    OMessageComp
} from '../../components/organisms'
import { SListInformation } from '../../components/species'
export default {
    components: {
        OHeaderViewComp,
        OCourseInformationComp,
        OMessageComp,
        SListInformation
    }
}
</script>