<template>
    <v-dialog
            :value="dialog"
            persistent
            width="360"
        >
            <v-card class="pa-2" color="#FAFAFA">
                <div class="d-flex justify-center">
                    <IconOperationLoader />
                </div>
                <h1 class="text-body-subtitle text-center">
                    {{message}}
                </h1>
            </v-card>
    </v-dialog>
</template>
<script>
import { IconOperationLoader } from '../../iconsFont'
export default {
    components: {
        IconOperationLoader
    },
    props: {
        dialog: {
            type: Boolean,
            default: false
        },
        message: String
    }
}
</script>
