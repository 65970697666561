export default {
    methods: {
        m_rule_emptyField(input) {
            if (input === null || input === '') {
                return true
            } else {
                var counter = 0
                var newinput = input + ""
                for (var i = 0; i < newinput.length; i++) {
                  if (newinput[i] === " ") ++counter
                }
                if (counter === newinput.length) return true
                return false
            }
        },
        m_rule_withSpaces(input) {
            var spaces = (input + "").match(/\s+/g)
            if (spaces === null) return false
            return true
        },
        m_rule_numberInteger(input) {
            if (isNaN(input)) return false
            else {
                var spaces = (input+"").match(/\W+/g)
                if (spaces === null) return true
                return false
            }
        },
        m_rule_numberDecimal(input) {
            if (isNaN(input)) return false
            else {
                if (input < 0) return false
                else return true
            }
        }
    }
}