<template>
    <div>
        <div class="d-flex align-center pa-1">
            <div class="d-flex align-center">
                <v-app-bar-nav-icon class="mr-2 attention-schedule__icon-menu" @click="m_menuComp_changeStateMenu()"></v-app-bar-nav-icon>
                <h1 class="text-header">HOME</h1>
            </div>
            <v-spacer></v-spacer>
            <OButtonCloseSesionComp
                :dataUser="dataUser"
                @click="ac_closedSession()"
                @clickaccount="ac_myAccount()"
            />
        </div>
        <MLineComp />
        <OLoaderOperationComp
            :dialog="dialogLoaderOperation"
            message="Cerrando Sesión"
        />
        <div class="d-flex justify-center mt-2 pa-2">
            <div>
                <div class="d-flex align-center">
                    <div class="ornament-circle mr-2"></div>
                    <h1 class="text-body-subtitle">
                        Si accede desde otro dispositivo, cierre
                        sesión al finalizar su uso.
                    </h1>
                </div>
                <div class="d-flex align-center mt-2">
                    <div class="ornament-circle mr-2"></div>
                    <h1 class="text-body-subtitle">
                        Cambie la contraseña de su cuenta de forma regular.
                    </h1>
                </div>
                <div class="d-flex align-center mt-2">
                    <div class="ornament-circle mr-2"></div>
                    <h1 class="text-body-subtitle">
                        Lea los mensajes de confirmación, antes de registrar o cambiar información
                    </h1>
                </div>
                <div class="d-flex align-center mt-2">
                    <div class="ornament-circle mr-2"></div>
                    <h1 class="text-body-subtitle">
                        No envie sus credenciales a terceros, ya que el acceso de la cuenta es solo
                        para una persona
                    </h1>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { MLineComp } from '../../components/molecules'
import {
    OButtonCloseSesionComp,
    OLoaderOperationComp
} from '../../components/organisms'
import {
    menuCompMixin,
    saveUserMixin
} from '../../mixins'
import { User } from '../../models'
export default {
    components: {
        MLineComp,
        OButtonCloseSesionComp,
        OLoaderOperationComp
    },
    mixins: [ menuCompMixin, saveUserMixin ],
    data: () => ({
        dialogLoaderOperation: false,
        dataUser: {}
    }),
    methods: {
        ac_closedSession() {
            this.dialogLoaderOperation = true
            User.closedSession().then(() => {
                this.dialogLoaderOperation = false
                localStorage.removeItem('sweetHome')
                this.$router.replace('/login')
            }).catch(() => {
                this.dialogLoaderOperation = false
                localStorage.removeItem('sweetHome')
                this.$router.replace('/login')
            })
        },
        ac_myAccount() {
            this.$router.push({
                'name': 'account'
            })
        }
    },
    mounted() {
        if (!this.m_saveDataUser()) this.$router.replace('/login')
        else this.dataUser = JSON.parse(localStorage.getItem('sweetHome'))
    }
    
}
</script>