<template>
    <div class="o-info-line d-flex">
        <div class="o-info-line__ornament"></div>
        <h1 class="text-subtitle">
            {{info}}
        </h1>
    </div>
</template>
<script>
export default {
    props: {
        info: String
    }
}
</script>