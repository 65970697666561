<template>
    <div class="o-message">
        <div class="o-message__container">
            <div class="o-message__img">
                <v-img
                    class="o-message__img-mobile"
                    :src="srcIcon"
                    width="100%"
                    height="100%"
                ></v-img>
                <v-img
                    class="o-message__img-pc"
                    src="/imgs/logos/house.svg"
                    width="100%"
                    height="100%"
                ></v-img>
            </div>
            <div class="o-message__info">
                <h1 class="text-title-header">{{title}}</h1>
            </div>
            <div class="o-message__ornament-icon">
                <!--<div style="width:40px; height:40px;">-->
                    <v-img
                        :src="srcIcon"
                        width="100%"
                        height="100%"
                    ></v-img>
                <!--</div>-->
            </div>
        </div>
        <div class="o-message__ornament"></div>
    </div>
</template>
<script>
export default {
    props: {
        title: String,
        srcIcon: String
    }
}
</script>