<template>
    <v-textarea
        outlined
        clearable
        dense
        @input="(e)=>(this.$emit('input', e))"
        :value="value"
        :label="label"
        :hide-details="hideDetails"
        :rules="rules"
        :counter="counter"
        :type="type"
        height="100"
    ></v-textarea>
</template>
<script>
export default {
    model: {
        prop: 'value',
        event: 'input'
    },
    props: {
        value: [String, Number],
        label: String,
        hideDetails: Boolean,
        rules: Array,
        counter: Number,
        type: String
    }
}
</script>