import axios from "axios";
import { apiUrls } from '../plugins/apiUrls'
export default class DataPublic{
    static getData() {
        return axios.get(
            apiUrls.DATA + '/data'
        ).then(response => {
            return response.data
        })
    }
    static getGalleries(page) {
        return axios.get(
            apiUrls.DATA + '/images-list?page=' + page
        ).then(response => {
            return response.data
        })
    }
    static newsList(page) {
        return axios.get(
            apiUrls.DATA + '/news-list?page=' + page
        ).then(response => {
            return response.data
        })
    }
}