<template>
    <div class="d-flex">
        <v-app-bar
            fixed
            class="menu-mobile-public"
            height="45px"
        >
            <div class="d-flex align-center" style="width:100%">
                <div class="d-flex align-center">
                    <v-img
                        src="/imgs/logo_colors.svg"
                        width="35px"
                        class="mr-2"
                    ></v-img>
                    <h1 class="text-login-sweet-mobile mr-2">Sweet</h1>
                    <h1 class="text-login-home-mobile">Home</h1>
                </div>
                <v-spacer></v-spacer>
                <v-app-bar-nav-icon
                    style="color:black"
                    class="mr-2"
                    @click="drawer=true"
                ></v-app-bar-nav-icon>
            </div>
        </v-app-bar>
        <v-navigation-drawer
                v-model="drawer"
                fixed
                temporary
                width="320"
                class="primary"
        >
            <v-toolbar class="menu-mobile__header">
                <div class="d-flex align-center">
                    <v-img
                        src="/imgs/logo_colors.svg"
                        width="40px"
                        class="mr-2"
                    ></v-img>
                    <h1 class="text-login-sweet-mobile mr-2">Sweet</h1>
                    <h1 class="text-login-home-mobile">Home</h1>
                </div>
                <v-spacer></v-spacer>
                <v-icon @click="drawer=!drawer" color="black">
                    mdi-close-circle
                </v-icon>
            </v-toolbar>
            <v-list nav class="pb-0 pt-0 mt-2">
                <div
                    v-for="(option, index) in listMenu"
                    :key="index"
                ><!--v-model="item.active"-->
                    <v-list-group
                        v-if="option.submenu.length>0"
                        no-action
                        :ripple="false"
                    >
                        <template v-slot:activator>
                            <v-list-item-icon class="mr-1">
                                <v-icon small color="secondary">{{option.icon}}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>
                                <h1 class="text-body-subtitle-inverse">{{option.title}}</h1>
                            </v-list-item-title>
                        </template>
                        <v-list-item
                            v-for="(child, index) in option.submenu"
                            :key="index"
                            class="pl-10"
                        >
                            <v-list-item-title class="d-flex align-center">
                                <div class="ornament-circle mr-2"></div>
                                <h1 class="text-body-subtitle-inverse">{{child.title}}</h1>
                            </v-list-item-title>
                        </v-list-item>
                    </v-list-group>
                    <v-list-item
                        v-else
                        :to="option.route"
                    >
                        <v-list-item-icon class="mr-1">
                            <v-icon small color="#eeeeeed2">{{option.icon}}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>
                            <h1 class="text-body-subtitle-inverse">{{option.title}}</h1>
                        </v-list-item-title>
                    </v-list-item>
                </div>
            </v-list>
        </v-navigation-drawer>
    </div>
</template>
<script>
export default {
    props: {
        listMenu: Array,
    },
    data: () => ({
        drawer: false
    })
}
</script>
