<template>
    <v-navigation-drawer
        permanent
        :mini-variant="mini"
        width="300"
        class="menu"
        fixed
    >
        <v-toolbar class="menu__logo d-flex align-center">
            <v-img
                src="/imgs/logo_colors.svg"
                width="35px"
                class="mr-4"
            ></v-img>
            <h1 class="text-login-sweet mr-2">Sweet</h1>
            <h1 class="text-login-home">Home</h1>
        </v-toolbar>
        <v-list nav class="ma-0 pa-1 pt-2">
            <div
                v-for="(option, index) in listMenu"
                :key="index"
            >
                <v-list-group
                    v-if="option.submenu.length>0"
                    no-action
                    :ripple="false"
                >
                    <template v-slot:activator>
                        <v-list-item-icon class="mr-1">
                            <v-tooltip bottom color="#ffff00">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                        v-bind="attrs"
                                        v-on="on"
                                        color="#eeeeeed2"
                                    >{{option.icon}}</v-icon>
                                </template>
                                <div class="d-flex align-center">
                                    <v-icon class="btn-animation-header__icon mr-1" color="black">mdi-hand-pointing-right</v-icon>
                                    <h1 class="text-body-subtitle">{{option.title}}</h1>
                                </div>
                            </v-tooltip>
                            <!--<v-icon small color="secondary">{{option.icon}}</v-icon>-->
                        </v-list-item-icon>
                        <v-list-item-title>
                            <h1 class="text-menu-subtitle">{{option.title}}</h1>
                        </v-list-item-title>
                    </template>
                    <v-list-item
                        v-for="(child, index) in option.submenu"
                        :key="index"
                        class="pl-10"
                    >
                        <v-list-item-title class="d-flex align-center">
                            <div class="ornament-circle mr-2"></div>
                            <h1 class="text-menu-subtitle">{{child.title}}</h1>
                        </v-list-item-title>
                    </v-list-item>
                </v-list-group>
                <v-list-item
                    v-else
                    :to="option.route"
                    dense
                    class="mt-1"
                    color="white"
                    :ripple="false"
                >
                    <v-list-item-icon class="mr-3" v-if="mini">
                        <v-tooltip bottom color="#ffff00">
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                    v-bind="attrs"
                                    v-on="on"
                                    color="#eeeeeed2"
                                >{{option.icon}}</v-icon>
                            </template>
                            <div class="d-flex align-center">
                                <v-icon class="btn-animation-header__icon mr-1" color="black">mdi-arrow-right-bold</v-icon>
                                <h1 class="text-body-title">{{option.title}}</h1>
                            </div>
                        </v-tooltip>
                    </v-list-item-icon>
                    <v-list-item-icon class="mr-3" v-else>
                        <v-icon color="#eeeeeed2">{{option.icon}}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>
                        <h1 class="text-body-subtitle-inverse">{{option.title}}</h1>
                    </v-list-item-title>
                </v-list-item>
            </div>
        </v-list>
    </v-navigation-drawer>
</template>
<script>
export default {
    props: {
        mini: Boolean,
        listMenu: Array
    },
    data: () => ({
        group: null
    })
}
</script>