<template>
    <div>
        <div class="d-flex justify-center">
            <div class="home-public__body animation--opacity">
                <OHeaderViewComp
                    src="/imgs/montessori/montessori.svg"
                    :title="$t('dataMontessori.headerInfo.title')"
                    :subtitle="$t('dataMontessori.headerInfo.subtitle')"
                />
                <OCourseInformationComp
                    class="space-top"
                    :listInfo="$t('dataMontessori.listInfo')"
                    src="/imgs/montessori/metodology_fond.png"
                />
                <OMessageComp
                    :title="$t('dataMontessori.messageInfo.title')"
                    class="space-top"
                    srcIcon="/imgs/montessori/icon_m1.png"
                />
                <SListInformation
                    :benefitsList="$t('dataMontessori.benefitsList')"
                    srcImg="/imgs/montessori/metodology_1.jpg"
                />
                <OMessageComp
                    :title="$t('dataMontessori.messageInfo.title_materials')"
                    class="space-top"
                    srcIcon="/imgs/montessori/icon_m2.png"
                />
                <SListInformation
                    class="space-bottom"
                    :benefitsList="$t('dataMontessori.materialBenefits')"
                    srcImg="/imgs/montessori/metodology_2.jpg"
                />
            </div>
        </div>
    </div>
</template>
<script>
import {
    OHeaderViewComp,
    OCourseInformationComp,
    OMessageComp
} from '../../components/organisms'
import {
    SListInformation
} from '../../components/species'
export default {
    components: {
        OHeaderViewComp,
        OCourseInformationComp,
        OMessageComp,
        SListInformation
    }
}
</script>