<template>
    <div class="d-flex justify-center">
        <div class="home-public__body animation--opacity">
            <OTitleGalleryComp
                src="/imgs/nest/nest.svg"
                :title="$t('dataPublic.titleNews')"
            />
            <div class="text-center gallery__empty-list pt-3" v-if="loaderData">
                <v-progress-circular
                indeterminate
                color="rgba(0,0,0,0.5)"
                ></v-progress-circular>
            </div>
            <div v-else>
                <div
                    v-for="(notice, index) in newsList"
                    :key="index"
                >
                    <OCardNewPublicComp
                        :notice="notice"
                        @click="ac_goNotice(notice)"
                    />
                    <div
                        v-if="index!=(newsList.length-1)"
                        class="o-header-view__info-line"    
                    ></div>
                </div>
                <div
                    v-if="newsList.length==0"
                    style="min-height:380px;"
                    class="mt-2"
                >
                    <MTitleNoData message="Sin Publicaciones" />
                </div>
            </div>
            <OPaginationComp
                v-if="newsList.length > 0 && !loaderData"
                :goCondition="page < last_page"
                :backCondition="page > 1"
                class="mt-2 mb-5"
                :titlePagination="'Pag. '+page+' de '+last_page"
                @clickback="ac_changePage(false)"
                @clickgo="ac_changePage(true)"
            />
        </div>
    </div>
</template>
<script>
import {
    imgMiniature,
    imgReal
} from '../../filters'
import {
    MTitleNoData
} from '../../components/molecules'
import {
    OCardNewPublicComp,
    OPaginationComp,
    OTitleGalleryComp
} from '../../components/organisms'
import { DataPublic } from '../../models'
import { animationScrollMixin } from '../../mixins'
export default {
    mixins: [ animationScrollMixin ],
    filters: {
        imgReal,
        imgMiniature
    },
    components: {
        OCardNewPublicComp,
        OPaginationComp,
        OTitleGalleryComp,
        MTitleNoData
    },
    data: () => ({
        newsList: [],
        page: 0,
        last_page: 0,
        loaderData: true
    }),
    methods: {
        getNews(page) {
            this.loaderData = true
            DataPublic.newsList(page).then(response => {
                this.newsList = response.data
                this.page = response.current_page
                this.last_page = response.last_page
                this.loaderData = false
            }).catch(error => {
                console.log(error)
            })
        },
        ac_changePage(state) {
            const time = this.m_scroll_time(window.scrollY)
            this.m_scroll_animation(time)
            setTimeout(() => {
                this.newsList = []
                if (state) this.page++
                else this.page--
                this.getNews(this.page)
            }, time)
        },
        ac_goNotice(notice) {
            this.$router.push({name: 'publication', params: {id: notice.id}})
        }
    },
    mounted() {
        this.getNews(1)
    }
}
</script>