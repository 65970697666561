<template>
    <div>
        <div class="d-flex justify-center">
            <div class="home-public__body animation--opacity">
                <OHeaderViewComp
                    src="/imgs/nook/nook.svg"
                    :title="$t('dataNook.headerInfo.title')"
                    :subtitle="$t('dataNook.headerInfo.subtitle')"
                />
                <OCourseInformationComp
                    class="space-top"
                    :withLogo="true"
                    :listInfo="$t('dataNook.listInfo')"
                    src="/imgs/nook/fond_nook.png"
                />
                <OMessageComp
                    :title="$t('dataNook.messageInfo.title')"
                    class="space-top"
                    srcIcon="/imgs/nook/icon_n.png"
                />
                <SListInformation
                    class="space-bottom"
                    :benefitsList="$t('dataNook.benefitsList')"
                    srcImg="/imgs/nook/nook_1.jpg"
                />
            </div>
        </div>
    </div>
</template>
<script>
import {
    OHeaderViewComp,
    OCourseInformationComp,
    OMessageComp
} from '../../components/organisms'
import { SListInformation } from '../../components/species'
export default {
    components: {
        OHeaderViewComp,
        OCourseInformationComp,
        OMessageComp,
        SListInformation
    }
}
</script>