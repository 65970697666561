<template>
    <div
        class="card-image"
        @click="$emit('click')"
    >
        <div class="card-image__img">
            <v-img
                :src="photography.route_photo|imgMiniature"
                width="100%"
                height="100%"
                contain
                class="card-image__photo"
                gradient="to top right, rgba(224, 113, 196, 0.25), rgba(224, 113, 196, 0)"
            >
            </v-img>
        </div>
        <div class="card-image__title">
            <h1 class="text-subtitle-inverse text-center">
                {{$i18n.locale!='es' ? photography.description_en : photography.description_es}}</h1>
        </div>
        <div class="card-image__logo">
            <v-img
                src="/imgs/logos/house_kinder.svg"
                contain
                position="center bottom"
            ></v-img>
        </div>
    </div>
</template>
<script>
import { imgMiniature } from '../../filters'
export default {
    filters: {
        imgMiniature
    },
    props: {
        photography: {}
    }
}
</script>