<template>
    <div class="o-menu-public">
        <div class="d-flex align-center o-menu-public__container">
            <router-link :to="{name: 'homePublic'}" style="text-decoration:none !important">
                <div class="o-menu-public__logo">
                    <v-img
                        src="/imgs/logo_colors.svg"
                        width="40px"
                        height="40px"
                        min-width="40px"
                        min-height="40px"
                    ></v-img>
                    <h1 class="text-menu-sweet mr-2 ml-2">Sweet</h1>
                    <h1 class="text-menu-home">Home</h1>
                </div>
            </router-link>
            <v-spacer></v-spacer>
            <div
                v-for="(option, index) in listMenu"
                :key="index"
            >
                <div v-if="option.submenu.length>0" class="mr-1">
                    <v-menu
                        transition="slide-x-transition"
                        offset-y
                        open-on-hover
                    >
                        <template v-slot:activator="{on, attrs}">
                            <v-btn
                                style="text-transform:none !important"
                                text
                                :ripple="false"
                                v-bind="attrs"
                                v-on="on"
                                active-class="menu-computer__btn"
                            >
                                <v-icon style="font-size:19px" color="#E573C4" class="mr-1">{{option.icon}}</v-icon>
                                <h1 class="text-menu-title">{{option.title}}</h1>
                            </v-btn>
                        </template>
                        <v-list class="pa-0 ma-0 pb-1 pt-1 menu-computer__list">
                            <v-list-item-group
                                color="primary"
                            >
                                <v-list-item
                                    v-for="(suboption, index) in option.submenu"
                                    :key="index"
                                    :to="{name: suboption.route}"
                                    :ripple="false"
                                    class="ma-1 menu-computer__submenu-item d-flex"
                                >
                                    <v-icon small color="#E573C4" class="mr-2">{{suboption.icon}}</v-icon>
                                    <h1 class="text-menu-title">{{suboption.title}}</h1>
                                </v-list-item>
                            </v-list-item-group>
                        </v-list>
                    </v-menu>
                </div>
                <div v-else class="mr-1">
                    <v-btn
                        style="text-transform:none !important; color:#f339f3 !important;"
                        text
                        :ripple="false"
                        :to="{name: option.route}"
                        active-class="o-menu-public__alone-btn"
                    >
                        <v-icon style="font-size:19px" color="#E573C4" class="mr-1">{{option.icon}}</v-icon>
                        <h1 class="text-menu-title">{{option.title}}</h1>
                    </v-btn>
                </div>
            </div>
            <v-menu
                transition="slide-x-transition"
                offset-y
                open-on-hover
            >
                <template v-slot:activator="{on, attrs}">
                    <v-btn
                        style="text-transform:none !important"
                        text
                        :ripple="false"
                        v-bind="attrs"
                        v-on="on"
                        active-class="menu-computer__btn"
                    >
                        <v-img
                            :src="$i18n.locale!='es' ? '/imgs/flags/eeuu.png' : '/imgs/flags/bolivia.png'"
                            width="23"
                            height="23"
                            contain
                        ></v-img>
                        <!--<h1 class="text-menu ml-1">
                            {{$i18n.locale!='es' ? $t('laguage.english') : $t('laguage.spanish')}}
                        </h1>-->
                    </v-btn>
                </template>
                <v-list class="pa-0 ma-0 pb-1 pt-1 menu-computer__list">
                    <v-list-item-group
                        color="primary"
                    >
                        <v-list-item
                            class="ma-1 menu-computer__submenu-item d-flex"
                            @click="changeLanguage('es')"
                        >
                            <v-img
                                src="/imgs/flags/bolivia.png"
                                width="23"
                                max-width="23"
                                height="23"
                            ></v-img>
                            <!--<h1 class="text-menu-title ml-1">{{$t('laguage.spanish')}}</h1>-->
                        </v-list-item>
                        <v-list-item
                            class="ma-1 menu-computer__submenu-item d-flex"
                            @click="changeLanguage('en')"
                        >
                            <v-img
                                src="/imgs/flags/eeuu.png"
                                width="23"
                                max-width="23"
                                height="23"
                            ></v-img>
                            <!--<h1 class="text-menu-title ml-1">{{$t('laguage.english')}}</h1>-->
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
            </v-menu>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        listMenu: Array
    },
    methods: {
        goHome() {
            this.$router.push({name: 'home'})
        },
        changeLanguage(language) {
            this.$i18n.locale = language;
            this.$router.push({
                params:{lang: language}
            })
        }
    }
}
</script>