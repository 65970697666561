<template>
    <div class="button-schedule">
        <v-menu
            transition="slide-x-transition"
            offset-y
            right
            content-class="button-schedule__menu"
        >
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                color="#39BCB8"
                small
                :ripple="false"
                v-bind="attrs"
                v-on="on"
                fab
            >
            <v-icon>mdi-calendar-clock</v-icon>
            </v-btn>
        </template>
        <div class="pt-2 pr-1">
            <div class="button-schedule__hours pa-2">
                <h1 class="text-body-title-inverse text-center">{{$t('dataPublic.schedule')}}</h1>
                <div
                    class="d-flex justify-center"
                    v-for="day in listSchedule"
                    :key="day.id"
                >
                    <div class="d-flex">
                        <div style="width:96px; min-width:96px;" class="mr-2">
                            <h1 class="text-body-subtitle-inverse text-right">
                                {{$i18n.locale!='es' ? day.day_en+':' : day.day_es+':'}}</h1>
                        </div>
                        <h1 class="text-body-subtitle-inverse">
                            {{day.schedule}}</h1>
                    </div>
                </div>
            </div>
        </div>
    </v-menu>
    </div>
</template>
<script>
export default {
    props: {
        listSchedule: []
    }
}
</script>