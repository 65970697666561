<template>
    <div class="s-list-information">
        <div class="s-list-information__img">
            <v-img
                :src="srcImg"
                width="100%"
                height="100%"
            ></v-img>
        </div>
        <div class="s-list-information__text">
            <OInfoLineComp
                v-for="(info, index) in benefitsList"
                :key="index"
                :info="info"
            />
        </div>
    </div>
</template>
<script>
import { OInfoLineComp } from '../organisms'
export default {
    components: {
        OInfoLineComp
    },
    props: {
        benefitsList: Array,
        srcImg: String
    }
}
</script>