<template>
    <div class="o-info-value">
        <div class="d-flex">
            <v-icon class="mr-2" color="#E573C4">{{info.icon}}</v-icon>
            <h1 style="color:#17AAA9" class="text-title">{{info.title}}</h1>
        </div>
        <div class="o-info-value__ornament"></div>
        <h1 class="text-subtitle">{{info.text}}</h1>
    </div>
</template>
<script>
export default {
    props: {
        info: {}
    }
}
</script>