<template>
    <div>
        <div class="d-flex justify-center s-background-public">
            <v-img
                :src="src"
                gradient="to top right, rgba(224, 113, 196, 0.4), rgba(224, 113, 196, 0)"
                height="100%"
                width="100%"
            ></v-img>
            <div class="s-background-public__info">
                <div class="s-background-public__info-logo">
                    <v-img
                        src="/imgs/home/logo_tranparent.png"
                        width="100%"
                        height="100%"
                    ></v-img>
                </div>
                <div class="d-flex align-center s-background-public__info-text">
                    <div>
                    <h1 class="text-background">
                        "Nos caracterizamos por ser una institución
                        bilingüe, cristiana, y personalizada."
                    </h1>
                    <div class="text-center mt-2">
                        <v-btn outlined dark small>CONOCENOS</v-btn>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        src: String
    }
}
</script>