<template>
    <div class="o-img-circle">
        <div class="o-img-circle__img" >
            <v-img
                :src="infoImg.src"
                width="100%"
                height="100%"
                class="o-img-circle__photo"
            ></v-img>
            <div class="o-img-circle__img-ornament d-flex justify-center">
                <div :style="`width:15px; height: 100%; background:${infoImg.color};`"></div>
            </div>
        </div>
        <div class="o-img-circle__title" :style="`background: ${infoImg.color}`">
            <h1 class="text-title-inverse text-center">
                {{infoImg.title}}</h1>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        infoImg: {},
        styleCircle: String
    },
    methods: {
        ac_getStyleBorder() {
            const borderSize = '2px'
            const styleBorder = `border-top: ${borderSize} solid ${this.infoImg.color};
                border-left: ${borderSize} solid ${this.infoImg.color};
                border-right: ${borderSize} solid ${this.infoImg.color};`
            return styleBorder
        }
    }
}
</script>