<template>
    <v-dialog persistent :value="dialog" max-width="380">
        <v-card class="pa-0" color="#FAFAFA">
            <div style="background:#d82323" class="d-flex justify-center pa-2">
                <h1 style="letter-spacing:1px;" class="text-body-title-inverse">ERROR</h1>
            </div>
            <v-card-title class="d-block pa-0 pt-2">
                <div class="d-flex justify-center">
                    <v-icon x-large class="animation-icon--rotate" color="#d82323">mdi-close-circle</v-icon>
                </div>
            </v-card-title>
            <v-card-text class="pa-1 pt-2 d-block">
                <h1
                    class="text-body-subtitle text-center"
                    v-for="(error, index) in smsError" :key="index"
                >
                    {{error}}
                </h1>
            </v-card-text>
            <v-card-actions class="pa-2 d-flex justify-center" v-if="actionError">
                <v-btn depressed :small="true" outlined color="#ff0000b0" @click="$emit('click')">Aceptar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
export default {
    model: {
        prop: 'dialog',
        event: 'click'
    },
    props: {
        dialog: Boolean,
        smsError: Array,
        actionError: Boolean
    }
}
</script>
