<template>
    <div class="o-header-view">
        <div class="o-header-view__info">
            <div class="d-flex align-center">
                <div class="o-header-view__info-img">
                    <v-img
                        src="/imgs/logos/house.svg"
                        width="100%"
                        max-width="100%"
                    ></v-img>
                </div>
                <h1 class="text-title-header mt-2">{{title}}</h1>
            </div>
            <div class="o-header-view__info-line"></div>
            <h1 class="text-subtitle-header o-header-view__space">{{subtitle}}</h1>
        </div>
        <div class="o-header-view__img-continer">
            <div class="o-header-view__img">
                <v-img
                    width="100%"
                    height="100%"
                    :src="src"
                    style="z-index:2"
                ></v-img>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        src: String,
        title: String,
        subtitle: String
    }
}
</script>