export default {
    namespaced: true,
    state: {
        header: {}
    },
    getters: {},
    mutations: {
        store_axiosHeader(state, payload){
            state.header = payload
        }
    }
}