<template>
    <div>
        <div class="d-flex align-center pa-1">
            <div class="d-flex align-center">
                <v-app-bar-nav-icon class="mr-2 attention-schedule__icon-menu" @click="m_menuComp_changeStateMenu()"></v-app-bar-nav-icon>
                <h1 class="text-header">FOTOGRAFÍAS</h1>
            </div>
            <v-spacer></v-spacer>
            <OButtonAnimateHeaderComp
                icon="mdi-plus"
                smsTooltip="Nueva fotografía"
                class="mr-1"
                @click="ac_openForm(true, {}, -1)"
            />
            <OButtonCloseSesionComp
                :dataUser="dataUser"
                @click="ac_closedSession()"
                @clickaccount="ac_myAccount()"
            />
        </div>
        <MLineComp />
        <OLoaderDataComp
            v-if="showLoaderData"
            class="mt-3"
            smsLoaderData="Obteniendo Fotografías"
        />
        <MTitleNoData
            message="SIN FOTOGRAFÍAS"
            class="mt-2"
            v-if="!showLoaderData && photoList.length==0"
        />
        <div class="d-flex justify-center flex-wrap mt-2">
            <div
                v-for="(photo, index) in photoList"
                :key="index"
                class="animation--opacity photographs__miniature ma-2"
            >
                <div class="pa-1 mb-2 d-flex align-center justify-center">
                    <h1 class="text-body-subtitle mr-2">
                        {{photo.enabled==1 ? 'Publicado' : 'No Publicado'}}</h1>
                    <div :class="photo.enabled==1 ? 'photographs__enable' : 'photographs__disabled'"></div>
                </div>
                <div class="photographs__miniature-img">
                    <v-img
                        :src="photo.route_photo|imgMiniature"
                        width="100%"
                        height="100%"
                        contain
                    ></v-img>
                </div>
                <h1 class="text-body-subtitle mt-1 text-center pa-1">
                    {{photo.description_es==null ? 'Sin título' : photo.description_es}}</h1>
                <div class="text-center mt-1 d-flex justify-center pa-1 pt-0 pb-2">
                    <OButtonActionCardComp
                        icon="mdi-format-list-bulleted"
                        class="mr-1"
                        smsTooltip="Detalle fotografía"
                        @click="ac_showDetail(photo)"
                    />
                    <OButtonActionCardComp
                        icon="mdi-pencil"
                        class="mr-1"
                        smsTooltip="Editar la fotografía"
                        @click="ac_openForm(false, photo, index)"
                    />
                    <OButtonActionCardComp
                        :icon="photo.enabled==1 ? 'mdi-eye-off' : 'mdi-eye'"
                        class="mr-1"
                        :smsTooltip="photo.enabled==1 ? 'Deja de publicar' : 'Publicar'"
                        @click="ac_changeState(photo)"
                    />
                    <OButtonActionCardComp
                        icon="mdi-trash-can"
                        class="mr-1"
                        smsTooltip="Eliminar la fotografía"
                        @click="ac_confirmDelete(photo)"
                    />
                </div>
            </div>
        </div>
        <OPaginationComp
            v-if="photoList.length > 0 && !showLoaderData"
            :goCondition="page < last_page"
            :backCondition="page > 1"
            class="mt-2 mb-5"
            :titlePagination="'Pag. '+page+' de '+last_page"
            @clickback="ac_changePage(false)"
            @clickgo="ac_changePage(true)"
        />
        <v-dialog
            persistent
            width="400"
            v-model="dialogForm"
        >
            <v-card class="pa-0">
                <ODialogHeaderComp
                    :title="indexPhoto==-1 ? 'Registrar Fotografía' : 'Editar Fotografía'"
                    @clickclose="ac_closeForm()"
                />
                <v-form
                    v-on:submit.prevent="ac_confirmRegistration()"
                    ref="formphotography"
                    v-model="valid"
                    lazy-validation
                    class="pa-3 pb-0"
                >
                    <div class="d-flex justify-center mb-1">
                        <div v-if="indexPhoto==-1">
                            <div
                                class="photographs__card-photo d-flex align-center mb-3"
                                @click='$refs.inputPhoto.click()'>
                                <div
                                    v-if="imgMiniature!=''"
                                    class="d-flex align-center"
                                    style="background:white; width:100%; height:100%;"
                                >
                                    <v-img
                                        :src="imgMiniature"
                                        width="100%"
                                        height="100%"
                                        contain
                                    ></v-img>
                                </div>
                                <div v-else class="d-block" style="width:100%">
                                    <h1 class="text-body-title text-center">SIN</h1>
                                    <h1 class="text-body-title text-center">FOTO</h1>
                                    <h1 class="text-body-title text-center">(Max. 4Mb)</h1>
                                </div>
                            </div>
                            <input
                                id="inputFile"
                                type="file"
                                style="display: none"
                                ref="inputPhoto"
                                accept="image/*"
                                @change="ac_onFilePicked"
                            >
                        </div>
                    </div>
                    <MTextFieldComp
                        v-model="dataPhoto.description_es"
                        label="Descripción (español)"
                        :counter="30"
                        :rules="[v => (v+'').length<=30 || 'La descripción no debe exeder los 50 dígitos']"
                    />
                    <MTextFieldComp
                        v-model="dataPhoto.description_en"
                        label="Descripción (inglés)"
                        :counter="30"
                        :rules="[v => (v+'').length<=30 || 'La descripción no debe exeder los 50 dígitos']"
                    />
                </v-form>
                <ODialogActionComp
                    actiontext="Guardar"
                    @click="ac_confirmRegistration()"
                />
            </v-card>
        </v-dialog>
        <v-dialog
            width="600"
            v-model="dialogDetail"
            persistent
        >
            <v-card class="pa-0 ma-0">
                <ODialogHeaderComp
                    title="Detalle Fotografía"
                    @clickclose="dialogDetail=false"
                />
                <div v-if="photo.id != undefined" class="pa-2">
                    <div class="d-flex">
                        <h1 class="text-body-title">Titulo (español):</h1>
                        <h1 class="text-body-subtitle ml-1">
                            {{photo.description_es ? photo.description_es : 'Sin título'}}
                        </h1>
                    </div>
                    <div class="d-flex">
                        <h1 class="text-body-title">Titulo (inglés):</h1>
                        <h1 class="text-body-subtitle ml-1">
                            {{photo.description_en ? photo.description_en : 'Sin título'}}
                        </h1>
                    </div>
                    <div class="mt-1 d-flex align-center">
                        <h1 class="text-body-title mr-2">
                           {{photo.enabled == 1 ? 'Publicado' : 'No publicado'}}</h1>
                        <div :class="photo.enabled == 0 ? 'photographs__disabled' : 'photographs__enable'"></div>
                    </div>
                    <h1 class="text-body-subtitle">
                        {{`Fecha de registro: ${photo.date}`}}
                    </h1>
                </div>
            </v-card>
        </v-dialog>
        <OLoaderOperationComp
            :dialog="dialogLoaderOperation"
            :message="smsLoaderOperation"
        />
        <OSmsErrorComp
            :dialog="dialogError"
            :smsError="smsError"
            :actionError="actionError"
            @click="ac_backError()"
        />
        <OSmsConfirmComp
            :dialog="dialogConfirm"
            :smsConfirm="smsConfirm"
            @clickyes="ac_performOperation()"
            @clickno="ac_backError()"
        />
        <OSmsActionComp
            @click="frameCorrect=false"
            :snakCorrecto="frameCorrect"
            :timeout="4000"
            :smsAction="smsAction"
        />
    </div>
</template>
<script>
import {
    MLineComp,
    MTitleNoData,
    MTextFieldComp
} from '../../components/molecules'
import {
    OButtonCloseSesionComp,
    OLoaderOperationComp,
    OSmsErrorComp,
    OSmsActionComp,
    OSmsConfirmComp,
    OButtonAnimateHeaderComp,
    OLoaderDataComp,
    ODialogHeaderComp,
    ODialogActionComp,
    OButtonActionCardComp,
    OPaginationComp
} from '../../components/organisms'
import {
    menuCompMixin,
    saveUserMixin,
    dataGeneralComponetsMixin,
    rulesMixin,
    responseServerMixin
} from '../../mixins'
import { User, Photography } from '../../models'
import { imgMiniature } from '../../filters'
export default {
    components: {
        MLineComp,
        MTitleNoData,
        OButtonCloseSesionComp,
        OLoaderOperationComp,
        OSmsErrorComp,
        OSmsActionComp,
        OSmsConfirmComp,
        OButtonAnimateHeaderComp,
        OLoaderDataComp,
        ODialogActionComp,
        ODialogHeaderComp,
        MTextFieldComp,
        OButtonActionCardComp,
        OPaginationComp
    },
    mixins: [
        menuCompMixin,
        saveUserMixin,
        dataGeneralComponetsMixin,
        rulesMixin,
        responseServerMixin
    ],
    filters: {
        imgMiniature
    },
    data: () => ({
        dataUser: {},
        valid: true,
        dialogForm: false,
        dataPhoto: new Photography(),
        imgMiniature: '',
        photoList: [],
        page: 0,
        last_page: 0,
        idPhotography: -1,
        indexPhoto: -1,
        isChangeState: false,
        photo: {},
        dialogDetail: false
    }),
    methods: {
        processError(error) {
            this.dialogLoaderOperation = false
            this.smsError = this.m_response_smsError(error)
            this.actionError = this.m_response_action(error)
            this.noAccess = this.m_response_autorization(error)
            this.dialogError = true
        },
        getPhotographs(page) {
            this.showLoaderData = true
            this.photoList = []
            Photography.photoList(page).then(response => {
                this.photoList = response.data
                this.page = response.current_page
                this.last_page = response.last_page
                this.showLoaderData = false
            }).catch(error => {
                this.processError(error)
            })
        },
        validateForm() {
            var admisiondatos = true
            if (!this.$refs.formphotography.validate()) { admisiondatos = false }
            return admisiondatos
        },
        showSmsAction(sms) {
            this.smsAction = sms
            this.frameCorrect = true
            this.timeSucess = setTimeout(() => {
                this.frameCorrect = false
            }, 4000)
        },
        addPhoto() {
            const dataPhotoFormat = this.$objectToFD(this.dataPhoto)
            Photography.addPhoto(dataPhotoFormat).then(response => {
                this.photoList.unshift(response)
                this.showSmsAction(['Registro exitoso',
                    'Se registró la fotografía'])
                this.ac_closeForm()
                this.dialogLoaderOperation = false
            }).catch(error => {
                this.processError(error)
            })
        },
        resetValuesDeleteChangeState() {
            this.isChangeState = false
            this.indexPhoto = -1
            this.idPhotography = -1
            this.dialogLoaderOperation = false
        },
        updatePhoto() {
            Photography.updatePhoto(this.dataPhoto).then(response => {
                this.photoList[this.indexPhoto].description_es = response.description_es
                this.photoList[this.indexPhoto].description_en = response.description_en
                this.showSmsAction(['Actualización exitosa',
                    'Se actualizó la fotografía'])
                this.ac_closeForm()
                this.dialogLoaderOperation = false
            }).catch(error => {
                this.processError(error)
            })
        },
        deletePhoto() {
            Photography.deletePhoto(this.idPhotography).then(() => {
                this.photoList.splice(this.indexPhoto, 1)
                this.showSmsAction(['Eliminación exitosa',
                    'Se eliminó la fotografía'])
                this.resetValuesDeleteChangeState()
            }).catch(error => {
                this.processError(error)
            })
        },
        changeState() {
            Photography.changeStatePhoto({'id': this.idPhotography}).then(() => {
                this.photoList[this.indexPhoto].enabled = !this.photoList[this.indexPhoto].enabled
                this.showSmsAction(['Operación exitosa',
                    'Se cambió el estado de la fotografía'])
                this.resetValuesDeleteChangeState()
            }).catch(error => {
                this.processError(error)
            })
        },
        // actions HTML
        ac_closedSession() {
            this.smsLoaderOperation = 'Cerrando Sesión'
            this.dialogLoaderOperation = true
            User.closedSession().then(() => {
                this.dialogLoaderOperation = false
                localStorage.removeItem('sweetHome')
                this.$router.replace('/login')
            }).catch(() => {
                this.dialogLoaderOperation = false
                localStorage.removeItem('sweetHome')
                this.$router.replace('/login')
            })
        },
        ac_onFilePicked(e) {
            const file = e.target.files[0]
            if (file !== undefined) {
                //if (file.size > 4194304) {//4mb
                if (file.size > 4194304) {//4mb
                    document.getElementById('inputFile').value = ''
                    this.dialogForm = false
                    this.smsError = ['La fotografía no puede ser mayor a 4Mb']
                    this.actionError = true
                    this.dialogError = true
                } else {
                    var date = new Date().getTime()
                    this.dataPhoto.route_photo = file
                    this.dataPhoto.name_photo = date
                    //this.loadImg(file)
                    let reader = new FileReader()
                    reader.onload = (e) => {
                        this.imgMiniature = e.target.result
                    }
                    reader.readAsDataURL(file)
                }
            } else {
                document.getElementById('inputFile').value = ''
                this.dataPhoto.route_photo = null
                this.dataPhoto.name_photo = null
                this.imgMiniature = ''
            }
        },
        ac_confirmRegistration() {
            this.dialogForm = false
            if (this.validateForm() && this.dataPhoto.route_photo !== null) {
                this.smsConfirm = [`¿Está seguro de registrar la fotografía?`]
                this.dialogConfirm = true
            } else {
                this.smsError = ['Seleccione una fotografía, porfavor']
                this.actionError = true
                this.dialogError = true
            }
        },
        ac_backError() {
            if (this.noAccess) {
                localStorage.removeItem('sweetHome')
                this.$router.replace('/login')
            } else {
                this.dialogConfirm = false
                this.dialogError = false
                this.isChangeState = false
                if (this.idPhotography === -1) this.dialogForm = true
                else this.idPhotography = -1
            }
        },
        ac_closeForm() {
            this.dialogForm = false
            this.$refs.formphotography.resetValidation()
            this.dataPhoto = new Photography()
            this.imgMiniature = ''
        },
        ac_performOperation() {
            this.dialogConfirm = false
            this.smsLoaderOperation = 'Realizando Operación'
            this.dialogLoaderOperation = true
            if (this.isChangeState)
                this.changeState()
            else {
                if (this.idPhotography !== -1)
                    this.deletePhoto()
                else {
                    if (this.indexPhoto !== -1) this.updatePhoto()
                    else this.addPhoto()
                }
            }
        },
        ac_openForm(isNew, photo, index) {
            this.indexPhoto = index
            if (!isNew) this.dataPhoto = Object.assign({}, photo)
            this.dialogForm = true
        },
        ac_confirmDelete(photo) {
            this.idPhotography = photo.id
            this.indexPhoto = this.photoList.indexOf(photo)
            this.smsConfirm = [`¿Está seguro de eliminar la fotografía?`]
            this.dialogConfirm = true
        },
        ac_changePage(state) {
            scroll(0, 0)
            if (state) { ++this.page }
            else { --this.page }
            setTimeout(() => {
                this.getPhotographs(this.page)
            }, 150)
        },
        ac_myAccount() {
            this.$router.push({
                'name': 'account'
            })
        },
        getStylesOrnament(photo) {
            if (photo.enabled == 1) return 'photographs__enable'
            return 'photographs__disabled'
        },
        ac_changeState(photo) {
            this.isChangeState = true
            this.indexPhoto = this.photoList.indexOf(photo)
            this.idPhotography = photo.id
            const message = photo.enabled == 0 ? '¿Está de seguro de publicar la fotografía?'
                : '¿Está seguro de dejar de publicar la fotografía?'
            this.smsConfirm = [message]
            this.dialogConfirm = true
        },
        ac_showDetail(photo) {
            this.photo = photo
            this.dialogDetail = true
        }
    },
    mounted() {
        if (!this.m_saveDataUser()) {
            this.$router.replace('/login')
        } else {
            this.dataUser = JSON.parse(localStorage.getItem('sweetHome'))
            this.getPhotographs(1)
        }
    }
}
</script>