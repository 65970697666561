<template>
    <div>
        <div class="d-flex justify-center">
            <div class="home-public__body animation--opacity">
                <OHeaderViewComp
                    src="/imgs/yarnAndGarden/icon_f.svg"
                    :title="$t('dataYarn.headerInfo.title')"
                    :subtitle="$t('dataYarn.headerInfo.subtitle')"
                />
                <OCourseInformationComp
                    class="space-top"
                    :withLogo="true"
                    :listInfo="$t('dataYarn.listInfo')"
                    src="/imgs/yarnAndGarden/yarn_and_garden.png"
                />
                <OMessageComp
                    :title="$t('dataYarn.titleImgs')"
                    class="space-top"
                    srcIcon="/imgs/yarnAndGarden/yarn_icon.png"
                />
                <div class="d-flex justify-center flex-wrap space-bottom">
                    <OImgCircleComp
                        class="ma-2 mt-4"
                        v-for="(infoImg, index) in $t('dataYarn.listImgs')"
                        :key="index"
                        :infoImg="infoImg"
                    />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {
    OHeaderViewComp,
    OCourseInformationComp,
    OMessageComp,
    OImgCircleComp
} from '../../components/organisms'
export default {
    components: {
        OHeaderViewComp,
        OCourseInformationComp,
        OMessageComp,
        OImgCircleComp
    }
}
</script>