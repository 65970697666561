export default {
    data: () => ({
        //loader operation
        dialogLoaderOperation: false,
        smsLoaderOperation: '',
        //loader data
        showLoaderData: false,
        smsLoaderData: '',
        //sms confirm
        dialogConfirm: false,
        smsConfirm: [],
        //sms error
        noAccess: false,
        dialogError: false,
        smsError: [],
        actionError: false,
        //sms correct
        smsAction: [],
        frameCorrect: false,
        colorAction: 'success',
        timeSucess: 0
    }),
    methods: {
        m_dataGeneral_frameTimeReset() {
            clearTimeout(this.timeSucess)
            this.frameCorrect = false
        }
    }
}