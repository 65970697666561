export default {
    methods: {
        m_schedule_orderDays(schedule) {
            var listDays = ['Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado', 'Domingo']
            var listOrderedList = []
            for(var i = 0; i < listDays.length; i++) {
                const listSchedule = schedule.filter(day => {
                return day.day_es === listDays[i]
                })
                listOrderedList.push(...listSchedule)
            }
            return listOrderedList
        }
    }
}