<template>
    <div>
        <div class="d-flex align-center pa-1">
            <div class="d-flex align-center">
                <v-app-bar-nav-icon
                    class="mr-2 attention-schedule__icon-menu"
                    @click="m_menuComp_changeStateMenu()">
                </v-app-bar-nav-icon>
                <h1 class="text-header">NOTICIAS</h1>
            </div>
            <v-spacer></v-spacer>
            <v-menu
                transition="slide-x-transition"
                offset-y
                open-on-hover
            >
                <template v-slot:activator="{on, attrs}">
                    <v-btn
                        style="text-transform:none !important"
                        text
                        :ripple="false"
                        v-bind="attrs"
                        v-on="on"
                        active-class="menu-computer__btn"
                    >
                        <v-img
                            :src="isSpanish ? '/imgs/flags/bolivia.png' : '/imgs/flags/eeuu.png'"
                            width="23"
                            height="23"
                            contain
                        ></v-img>
                    </v-btn>
                </template>
                <v-list class="pa-0 ma-0 pb-1 pt-1 menu-computer__list">
                    <v-list-item-group
                        color="primary"
                    >
                        <v-list-item
                            class="ma-1 menu-computer__submenu-item d-flex"
                            @click="isSpanish=true"
                        >
                            <v-img
                                src="/imgs/flags/bolivia.png"
                                width="23"
                                max-width="23"
                                height="23"
                            ></v-img>
                            <!--<h1 class="text-menu-title ml-1">{{$t('laguage.spanish')}}</h1>-->
                        </v-list-item>
                        <v-list-item
                            class="ma-1 menu-computer__submenu-item d-flex"
                            @click="isSpanish=false"
                        >
                            <v-img
                                src="/imgs/flags/eeuu.png"
                                width="23"
                                max-width="23"
                                height="23"
                            ></v-img>
                            <!--<h1 class="text-menu-title ml-1">{{$t('laguage.english')}}</h1>-->
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
            </v-menu>
            <OButtonAnimateHeaderComp
                icon="mdi-plus"
                smsTooltip="Nueva Noticia"
                class="mr-1 ml-2"
                @click="ac_openForm(true, {}, -1)"
            />
            <OButtonCloseSesionComp
                :dataUser="dataUser"
                @click="ac_closedSession()"
                @clickaccount="ac_myAccount()"
            />
        </div>
        <MLineComp />
        <OLoaderDataComp
            v-if="showLoaderData"
            class="mt-3"
            smsLoaderData="Obteniendo Noticias"
        />
        <MTitleNoData
            message="SIN NOTICIAS"
            class="mt-2"
            v-if="!showLoaderData && noticesList.length==0"
        />
        <div class="d-flex justify-center flex-wrap mt-2">
            <div
                v-for="(notice, index) in noticesList"
                :key="index"
                class="animation--opacity photographs__miniature ma-2"
            >
                <div class="pa-1 mb-2 d-flex align-center justify-center">
                    <h1 class="text-body-subtitle mr-2">
                        {{notice.enabled==1 ? 'Publicado' : 'No Publicado'}}</h1>
                    <div :class="notice.enabled==1 ? 'photographs__enable' : 'photographs__disabled'"></div>
                </div>
                <v-img
                    v-if="isSpanish"
                    :src="notice.route_photo_es|imgMiniature"
                    width="240px"
                    height="180px"
                    style="background:white"
                    contain
                ></v-img>
                <v-img
                    v-else
                    :src="notice.route_photo_en|imgMiniature"
                    width="240px"
                    height="180px"
                    style="background:white"
                    contain
                ></v-img>
                <h1 class="text-body-subtitle mt-1 text-center pa-1">
                    {{isSpanish ? notice.title_es : notice.title_en}}</h1>
                <div class="text-center mt-1 d-flex justify-center flex-wrap pa-1 pt-0 pb-2">
                    <OButtonActionCardComp
                        icon="mdi-format-list-bulleted"
                        class="mr-1"
                        smsTooltip="Mostrar detalle"
                        @click="ac_showDetail(notice)"
                    />
                    <OButtonActionCardComp
                        :icon="notice.enabled==1 ? 'mdi-eye-off' : 'mdi-eye'"
                        class="mr-1"
                        :smsTooltip="notice.enabled==1 ? 'Deja de publicar' : 'Publicar'"
                        @click="ac_changeState(notice)"
                    />
                    <OButtonActionCardComp
                        icon="mdi-pencil"
                        class="mr-1"
                        smsTooltip="Editar la noticia"
                        @click="ac_openForm(false, notice, index)"
                    />
                    <OButtonActionCardComp
                        icon="mdi-trash-can"
                        smsTooltip="Eliminar la noticia"
                        class="mr-1"
                        @click="ac_confirmDelete(notice)"
                    />
                </div>
            </div>
        </div>
        <OPaginationComp
            v-if="noticesList.length > 0 && !showLoaderData"
            :goCondition="page < last_page"
            :backCondition="page > 1"
            class="mt-2 mb-5"
            :titlePagination="'Pag. '+page+' de '+last_page"
            @clickback="ac_changePage(false)"
            @clickgo="ac_changePage(true)"
        />
        <v-dialog
            persistent
            width="550"
            v-model="dialogForm"
        >
            <v-card class="pa-0">
                <ODialogHeaderComp
                    :title="indexNotice==-1 ? 'Registrar Fotografía' : 'Editar Fotografía'"
                    @clickclose="ac_closeForm()"
                />
                <v-form
                    v-on:submit.prevent="ac_confirmRegistration()"
                    ref="formnotice"
                    v-model="valid"
                    lazy-validation
                    class="pa-3 pb-0"
                >
                    <MTextFieldComp
                        v-model="dataNotice.title_es"
                        label="Título (español)"
                        :counter="50"
                        class="mt-1 mb-1"
                        :rules="[
                            v => !m_rule_emptyField(v) || 'El título es requerido',
                            v => (v+'').length<=50 || 'El título no debe exeder los 50 dígitos']"
                    />
                    <MTextFieldComp
                        v-model="dataNotice.title_en"
                        label="Título (inglés)"
                        :counter="50"
                        class="mt-1 mb-1"
                        :rules="[
                            v => !m_rule_emptyField(v) || 'El título es requerido',
                            v => (v+'').length<=50 || 'El título no debe exeder los 50 dígitos']"
                    />
                    <div class="d-flex justify-center mb-1" v-if="indexNotice==-1">
                        <div class="mr-2">
                            <div
                                class="photographs__card-photo d-flex align-center mb-3"
                                @click='$refs.inputPhotoEs.click()'>
                                <div
                                    v-if="imgMiniature_es!=''"
                                    class="d-flex align-center"
                                    style="background:white; width:100%; height:100%;"
                                >
                                    <v-img
                                        :src="imgMiniature_es"
                                        width="100%"
                                        height="100%"
                                        contain
                                    ></v-img>
                                </div>
                                <div v-else class="d-block" style="width:100%">
                                    <h1 class="text-body-title text-center">SIN FOTO</h1>
                                    <h1 class="text-body-title text-center">ESPAÑOL</h1>
                                    <h1 class="text-body-title text-center">(Max. 4Mb)</h1>
                                </div>
                            </div>
                            <input
                                id="inputFileSpanish"
                                type="file"
                                style="display: none"
                                ref="inputPhotoEs"
                                accept="image/*"
                                @change="ac_onFilePickedEs"
                            >
                        </div>
                        <div>
                            <div
                                class="photographs__card-photo d-flex align-center mb-3"
                                @click='$refs.inputPhotoEn.click()'>
                                <div
                                    v-if="imgMiniature_en!=''"
                                    class="d-flex align-center"
                                    style="background:white; width:100%; height:100%;"
                                >
                                    <v-img
                                        :src="imgMiniature_en"
                                        width="100%"
                                        height="100%"
                                        contain
                                    ></v-img>
                                </div>
                                <div v-else class="d-block" style="width:100%">
                                    <h1 class="text-body-title text-center">SIN FOTO</h1>
                                    <h1 class="text-body-title text-center">INGLÉS</h1>
                                    <h1 class="text-body-title text-center">(Max. 4Mb)</h1>
                                </div>
                            </div>
                            <input
                                id="inputFileEnglish"
                                type="file"
                                style="display: none"
                                ref="inputPhotoEn"
                                accept="image/*"
                                @change="ac_onFilePickedEn"
                            >
                        </div>
                    </div>
                    <MTextAreaComp
                        v-model="dataNotice.description_es"
                        label="Descripción (español)"
                        :counter="500"
                        class="mt-2 mb-1"
                        :rules="[
                            v => (v+'').length <= 500 || 'La descripción no debe exeder los 500 dígitos',
                            v => ac_validateDescription(v, dataNotice.description_en) || 'Debe introducir la descripción en español'
                        ]"
                    />
                    <MTextAreaComp
                        v-model="dataNotice.description_en"
                        label="Descripción (inglés)"
                        :counter="500"
                        class="mt-2 mb-1"
                        :rules="[
                            v => (v+'').length<=500 || 'La descripción no debe exeder los 500 dígitos',
                            v => ac_validateDescription(v, dataNotice.description_es) || 'Debe introducir la descripción en inglés'
                        ]"
                    />
                    <ODateOneComp
                        v-model="date_promise_format"
                        label="Válido Hasta"
                        :rules="[
                            v => !m_rule_emptyField(v) || 'La fecha es requerida',
                            v => !ac_validDatePromise(v) || 'La fecha debe ser mayor a la fecha actual'
                        ]"
                        @change="ac_electionDatePromise"
                    />
                </v-form>
                <ODialogActionComp
                    actiontext="Guardar"
                    @click="ac_confirmRegistration()"
                />
            </v-card>
        </v-dialog>
        <v-dialog
            width="600"
            v-model="dialogDetail"
            persistent
        >
            <v-card class="pa-0 ma-0">
                <ODialogHeaderComp
                    title="Detalle Noticia"
                    @clickclose="dialogDetail=false"
                />
                <div v-if="notice.title_es != undefined" class="pa-2">
                    <h1 class="text-body-title">
                        {{isSpanish ? notice.title_es : notice.title_en}}
                    </h1>
                    <div class="just-line-break">
                        <h1 class="text-body-subtitle">
                            {{isSpanish ? notice.description_es : notice.description_en}}
                        </h1>
                    </div>
                    <div class="mt-1 d-flex align-center">
                        <h1 class="text-body-title mr-2">
                            {{'Válido Hasta: '+m_date_formatDate(notice.date_promise)}}</h1>
                        <div :class="ac_getColorCard(notice)"></div>
                    </div>
                    <div class="mt-1 d-flex align-center">
                        <h1 class="text-body-title mr-2">
                           {{notice.enabled == 1 ? 'Publicado' : 'No publicado'}}</h1>
                        <div :class="notice.enabled == 0 ? 'photographs__disabled' : 'photographs__enable'"></div>
                    </div>
                    <h1 class="text-body-subtitle">
                        {{`Fecha de registro: ${notice.date}`}}
                    </h1>
                </div>
            </v-card>
        </v-dialog>
        <OLoaderOperationComp
            :dialog="dialogLoaderOperation"
            :message="smsLoaderOperation"
        />
        <OSmsErrorComp
            :dialog="dialogError"
            :smsError="smsError"
            :actionError="actionError"
            @click="ac_backError()"
        />
        <OSmsConfirmComp
            :dialog="dialogConfirm"
            :smsConfirm="smsConfirm"
            @clickyes="ac_performOperation()"
            @clickno="ac_backError()"
        />
        <OSmsActionComp
            @click="frameCorrect=false"
            :snakCorrecto="frameCorrect"
            :timeout="4000"
            :smsAction="smsAction"
        />
    </div>
</template>
<script>
import {
    MLineComp,
    MTextFieldComp,
    MTitleNoData,
    MTextAreaComp
} from '../../components/molecules'
import {
    OLoaderOperationComp,
    OSmsErrorComp,
    OSmsActionComp,
    OSmsConfirmComp,
    OButtonAnimateHeaderComp,
    OButtonCloseSesionComp,
    OLoaderDataComp,
    OButtonActionCardComp,
    ODialogHeaderComp,
    ODialogActionComp,
    ODateOneComp,
    OPaginationComp
} from '../../components/organisms'
import {
    menuCompMixin,
    saveUserMixin,
    dataGeneralComponetsMixin,
    rulesMixin,
    responseServerMixin,
    dateMixin
} from '../../mixins'
import { User, Notice } from '../../models'
import { imgMiniature } from '../../filters'
export default {
    components: {
        OLoaderOperationComp,
        OSmsErrorComp,
        OSmsActionComp,
        OSmsConfirmComp,
        MLineComp,
        OButtonAnimateHeaderComp,
        OButtonCloseSesionComp,
        OLoaderDataComp,
        OButtonActionCardComp,
        ODialogHeaderComp,
        ODialogActionComp,
        MTextFieldComp,
        ODateOneComp,
        OPaginationComp,
        MTitleNoData,
        MTextAreaComp
    },
    filters: {
        imgMiniature
    },
    mixins: [
        menuCompMixin,
        saveUserMixin,
        dataGeneralComponetsMixin,
        rulesMixin,
        responseServerMixin,
        dateMixin
    ],
    data: () => ({
        dataUser: {},
        dataNotice: new Notice(),
        page: 0,
        last_page: 0,
        noticesList: [],
        dialogForm: false,
        idNotice: -1,
        indexNotice: -1,
        imgMiniature_es: '',
        imgMiniature_en: '',
        valid: true,
        date_promise_format: null,
        isSpanish: true,
        dialogDetail: false,
        notice: {},
        isChangeState: false
    }),
    methods: {
        processError(error) {
            this.dialogLoaderOperation = false
            this.smsError = this.m_response_smsError(error)
            this.actionError = this.m_response_action(error)
            this.noAccess = this.m_response_autorization(error)
            this.dialogError = true
        },
        getNotices(page) {
            this.noticesList = []
            this.showLoaderData = true
            Notice.noticesList(page).then(response => {
                this.noticesList = response.data
                this.page = response.current_page
                this.last_page = response.last_page
                this.showLoaderData = false
            }).catch(error => {
                this.processError(error)
            })
        },
        showSmsAction(sms) {
            this.smsAction = sms
            this.frameCorrect = true
            this.timeSucess = setTimeout(() => {
                this.frameCorrect = false
            }, 4000)
        },
        addNotice() {
            const dataNoticeFormat = this.$objectToFD(this.dataNotice)
            Notice.addNotice(dataNoticeFormat).then(response => {
                this.noticesList.unshift(response)
                this.showSmsAction(['Registro exitoso',
                    'Se registró la noticia'])
                this.ac_closeForm()
                this.dialogLoaderOperation = false
            }).catch(error => {
                this.processError(error)
            })
        },
        updateNotice() {
            Notice.updateNotice(this.dataNotice).then(response => {
                Object.assign(this.noticesList[this.indexNotice], response)
                //this.noticesList[this.indexNotice] = response
                this.showSmsAction(['Actualización exitosa',
                    'Se actualizó la noticia'])
                this.ac_closeForm()
                this.dialogLoaderOperation = false
            }).catch(error => {
                this.processError(error)
            })
        },
        deleteNotice() {
            Notice.deleteNotice(this.idNotice).then(() => {
                this.noticesList.splice(this.indexNotice, 1)
                this.showSmsAction(['Eliminación exitosa',
                    'Se eliminó la noticia'])
                this.resetValuesDeleteChangeState()
            }).catch(error => {
                this.processError(error)
            })
        },
        resetValuesDeleteChangeState() {
            this.isChangeState = false
            this.indexNotice = -1
            this.idNotice = -1
            this.dialogLoaderOperation = false
        },
        changeState() {
            Notice.changeStateNotice({id: this.idNotice}).then(() => {
                this.noticesList[this.indexNotice].enabled = !this.noticesList[this.indexNotice].enabled
                this.showSmsAction(['Operación exitosa',
                    'Se cambió el estado de la Noticia'])
                this.resetValuesDeleteChangeState()
            }).catch(error => {
                this.processError(error)
            })
        },
        // actions HTML
        ac_closedSession() {
            this.smsLoaderOperation = 'Cerrando Sesión'
            this.dialogLoaderOperation = true
            User.closedSession().then(() => {
                this.dialogLoaderOperation = false
                localStorage.removeItem('sweetHome')
                this.$router.replace('/login')
            }).catch(() => {
                this.dialogLoaderOperation = false
                localStorage.removeItem('sweetHome')
                this.$router.replace('/login')
            })
        },
        ac_myAccount() {
            this.$router.push({
                'name': 'account'
            })
        },
        ac_onFilePickedEs(e) {
            const file = e.target.files[0]
            if (file !== undefined) {
                if (file.size > 4194304) {//4mb
                    document.getElementById('inputFileSpanish').value = ''
                    this.dialogForm = false
                    this.smsError = ['La fotografía no puede ser mayor a 4Mb']
                    this.actionError = true
                    this.dialogError = true
                } else {
                    var date = new Date().getTime()
                    this.dataNotice.route_photo_es = file
                    this.dataNotice.name_photo_es = 'es' + date
                    let reader = new FileReader()
                    reader.onload = (e) => {
                        this.imgMiniature_es = e.target.result
                    }
                    reader.readAsDataURL(file)
                }
            } else {
                document.getElementById('inputFileSpanish').value = ''
                this.dataNotice.route_photo_es = null
                this.dataNotice.name_photo_es = null
                this.imgMiniature_es = ''
            }
        },
        ac_onFilePickedEn(e) {
            const file = e.target.files[0]
            if (file !== undefined) {
                if (file.size > 4194304) {//4mb
                    document.getElementById('inputFileEnglish').value = ''
                    this.dialogForm = false
                    this.smsError = ['La fotografía no puede ser mayor a 4Mb']
                    this.actionError = true
                    this.dialogError = true
                } else {
                    var date = new Date().getTime()
                    this.dataNotice.route_photo_en = file
                    this.dataNotice.name_photo_en = 'en' + date
                    let reader = new FileReader()
                    reader.onload = (e) => {
                        this.imgMiniature_en = e.target.result
                    }
                    reader.readAsDataURL(file)
                }
            } else {
                document.getElementById('inputFileEnglish').value = ''
                this.dataNotice.route_photo_en = null
                this.dataNotice.name_photo_en = null
                this.imgMiniature_en = ''
            }
        },
        ac_openForm(isNew, notice, index) {
            this.indexNotice = index
            if (!isNew) {
                this.dataNotice = Object.assign({}, notice)
                this.date_promise_format = this.m_date_formatDate(notice.date_promise)
            }
            this.dialogForm = true
        },
        ac_electionDatePromise(e) {
            this.dataNotice.date_promise = e
            this.date_promise_format = this.m_date_formatDate(e)
        },
        ac_validDatePromise(date) {
            if (this.m_date_formatEstandar(date) < this.m_date_systemDate(false)) return true
            return false
        },
        ac_closeForm() {
            this.dialogForm = false
            this.$refs.formnotice.resetValidation()
            this.dataNotice = new Notice()
            this.date_promise_format = null
            this.imgMiniature_es = ''
            this.imgMiniature_en = ''
        },
        validateForm() {
            var admisiondatos = true
            if (!this.$refs.formnotice.validate()) { admisiondatos = false }
            return admisiondatos
        },
        ac_confirmRegistration() {
            this.dialogForm = false
            if (this.validateForm() && this.dataNotice.route_photo_es !== null && this.dataNotice.route_photo_en !== null) {
                this.smsConfirm = [`¿Está seguro de registrar la noticia?`]
                this.dialogConfirm = true
            } else {
                this.smsError = [
                    `Corrija los datos marcados con rojo`,
                    `Verifique que haya introducido las dos fotografías`
                ]
                this.actionError = true
                this.dialogError = true
            }
        },
        ac_backError() {
            if (this.noAccess) {
                localStorage.removeItem('sweetHome')
                this.$router.replace('/login')
            } else {
                this.dialogConfirm = false
                this.dialogError = false
                this.isChangeState = false
                if (this.idNotice === -1) this.dialogForm = true
                else this.idNotice = -1
            }
        },
        ac_performOperation() {
            this.dialogConfirm = false
            this.smsLoaderOperation = 'Realizando Operación'
            this.dialogLoaderOperation = true
            if (this.isChangeState)
                this.changeState()
            else {
                if (this.idNotice !== -1) this.deleteNotice()
                else {
                    if (this.indexNotice !== -1) this.updateNotice()
                    else this.addNotice()
                }
            }
        },
        ac_confirmDelete(notice) {
            this.idNotice = notice.id
            this.indexNotice = this.noticesList.indexOf(notice)
            this.smsConfirm = [`¿Está seguro de eliminar la noticia?`]
            this.dialogConfirm = true
        },
        ac_getColorCard(notice) {
            if (notice.date_promise < this.m_date_systemDate(false)) return 'photographs__disabled'
            return 'photographs__enable'
        },
        ac_changePage(state) {
            scroll(0, 0)
            if (state) { ++this.page }
            else { --this.page }
            setTimeout(() => {
                this.getNotices(this.page)
            }, 150)
        },
        ac_validateDescription(textArea, otherTextArea) {
            if (!this.m_rule_emptyField(otherTextArea))
                if (this.m_rule_emptyField(textArea)) return false
            return true
        },
        ac_showDetail(notice) {
            this.notice = notice
            this.dialogDetail = true
        },
        ac_changeState(notice) {
            this.isChangeState = true
            this.indexNotice = this.noticesList.indexOf(notice)
            this.idNotice = notice.id
            const message = notice.enabled == 0 ? '¿Está de seguro de publicar la noticia?'
                : '¿Está seguro de dejar de publicar la noticia?'
            this.smsConfirm = [message]
            this.dialogConfirm = true
        }
    },
    mounted() {
        if (!this.m_saveDataUser()) {
            this.$router.replace('/login')
        } else {
            this.dataUser = JSON.parse(localStorage.getItem('sweetHome'))
            this.getNotices(1)
        }
    }
}
</script>