<template>
    <div class="blanco pb-2">
        <div class="d-flex justify-center align-center contenidopag">
            <h1>{{titlePagination}}</h1>
            <v-btn
                :disabled="!backCondition"
                small
                color="primary"
                class="ml-2"
                @click="$emit('clickback')"
                fab
                depressed
            >
                <v-icon color="white">mdi-chevron-left</v-icon>
            </v-btn>
            <v-btn
                depressed
                :disabled="!goCondition"
                small
                color="primary"
                class="ml-2"
                @click="$emit('clickgo')"
                fab
            >
                <v-icon color="white">mdi-chevron-right</v-icon>
            </v-btn>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        titlePagination: String,
        backCondition: Boolean,
        goCondition: Boolean
    }
}
</script>
<style scoped>
    .contenidopag h1{
        color: #075866;
        font-size: 18px;
        font-weight: 400;
        text-shadow: 0px 0px 4px rgb(194, 195, 202);
    }
</style>