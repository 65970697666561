import axios from "axios"
import { apiUrls } from "../plugins/apiUrls"
import { headerAxiosStore } from "../store/modules"
export default class Photography {
    constructor(
        description_es = null,
        description_en = null,
        route_photo = null,
        name_photo = null
    ){
        this.description_es = description_es
        this.description_en = description_en
        this.route_photo = route_photo
        this.name_photo = name_photo
    }
    static photoList(page) {
        return axios.get(
            apiUrls.PHOTOGRAPHY + '/list?page=' + page,
            { headers: headerAxiosStore.state.header }
        ).then(response => {
            return response.data
        })
    }
    static addPhoto(parameters) {
        return axios.post(
            apiUrls.PHOTOGRAPHY + '/add',
            parameters,
            { headers: headerAxiosStore.state.header }
        ).then(response => {
            return response.data
        })
    }
    static updatePhoto(parameters) {
        return axios.put(
            apiUrls.PHOTOGRAPHY + '/update',
            parameters,
            { headers: headerAxiosStore.state.header }
        ).then(response => {
            return response.data
        })
    }
    static deletePhoto(id) {
        return axios.delete(
            apiUrls.PHOTOGRAPHY + '/delete/' + id,
            { headers: headerAxiosStore.state.header }
        ).then(response => {
            return response.data
        })
    }
    static changeStatePhoto(parameters) {
        return axios.patch(
            apiUrls.PHOTOGRAPHY + '/change-state',
            parameters,
            { headers: headerAxiosStore.state.header }
        ).then(response => {
            return response.data
        })
    }
    static downloadImage(route_file) {
        window.open(`${apiUrls.PHOTOGRAPHY}/download?route_file=${route_file}`, '_blank')
    }
}