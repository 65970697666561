import axios from "axios"
import { apiUrls } from "../plugins/apiUrls"
import { headerAxiosStore } from "../store/modules"
export default class Notice {
    constructor(
        title_es = null,
        route_photo_es = null,
        name_photo_es = null,
        title_en = null,
        route_photo_en = null,
        name_photo_en = null,
        description_es = null,
        description_en = null,
        date_promise = null
    ){
        this.title_es = title_es
        this.title_en = title_en
        this.route_photo_es = route_photo_es
        this.route_photo_en = route_photo_en
        this.name_photo_es = name_photo_es
        this.name_photo_en = name_photo_en
        this.description_en = description_en
        this.description_es = description_es
        this.date_promise = date_promise
    }
    static noticesList(page) {
        return axios.get(
            apiUrls.NOTICE + '/list?page=' + page,
            { headers: headerAxiosStore.state.header }
        ).then(response => {
            return response.data
        })
    }
    static addNotice(parameters) {
        return axios.post(
            apiUrls.NOTICE + '/add',
            parameters,
            { headers: headerAxiosStore.state.header }
        ).then(response => {
            return response.data
        })
    }
    static updateNotice(parameters) {
        return axios.put(
            apiUrls.NOTICE + '/update',
            parameters,
            { headers: headerAxiosStore.state.header }
        ).then(response => {
            return response.data
        })
    }
    static deleteNotice(id) {
        return axios.delete(
            apiUrls.NOTICE + '/delete/' + id,
            { headers: headerAxiosStore.state.header }
        ).then(response => {
            return response.data
        })
    }
    static getPublication(id) {
        return axios.get(
            apiUrls.NOTICE + '/publication?id='+id
        ).then(response => {
            return response.data
        })
    }
    static getRecommendations(id, quantity) {
        return axios.get(
            apiUrls.NOTICE + '/recommendation?id='+id+'&quantity='+quantity
        ).then(response => {
            return response.data
        })
    }
    static changeStateNotice(parameters) {
        return axios.patch(
            apiUrls.NOTICE + '/change-state',
            parameters,
            { headers: headerAxiosStore.state.header }
        ).then(response => {
            return response.data
        })
    }
}