export default {
    namespaced: true,
    state: {
        listSchedule: []
    },
    getters: {},
    mutations: {
        addSchedule(state, payload){
            state.listSchedule = payload
        }
    }
}