import { mapMutations } from 'vuex'
export default {
    methods: {
        ...mapMutations('headerAxiosStore', ['store_axiosHeader']),
        m_saveDataUser() {
            if (JSON.parse(localStorage.getItem('sweetHome'))) {
                var apitoken = JSON.parse(localStorage.getItem('sweetHome')).api_token
                var header = {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": "Bearer " + apitoken,
                }
                this.store_axiosHeader(header)
                return true
            } else {
                return false
            }
        }
    }
}