import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import {
  menuCompStore,
  headerAxiosStore,
  scheduleStore
} from './modules'

export default new Vuex.Store({
  modules: {
    menuCompStore,
    headerAxiosStore,
    scheduleStore
  }
})
