<template>
    <div class="card-new-public">
        <div class="card-new-public__img">
            <v-img
                v-if="$i18n.locale!='en'"
                :src="notice.route_photo_es|imgMiniature"
                width="100%"
                height="100%"
                contain
                gradient="to top right, rgba(224, 113, 196, 0.25), rgba(224, 113, 196, 0)"
            ></v-img>
            <v-img
                v-else
                :src="notice.route_photo_en|imgMiniature"
                width="100%"
                height="100%"
                contain
                gradient="to top right, rgba(224, 113, 196, 0.25), rgba(224, 113, 196, 0)"
            ></v-img>
        </div>
        <div class="card-new-public__info">
            <h1 class="text-title">
                {{$i18n.locale!='en' ? notice.title_es : notice.title_en}}</h1>
            <h1 style="font-size:15px" class="text-subtitle mt-1">
                {{$i18n.locale!='en' ? `Publicación: ${notice.date}` : `Publication: ${notice.date}`}}</h1>
            <v-divider
                class="mt-1 mb-1"
                v-if="notice.description_es!=null"
            ></v-divider>
            <h1 class="text-subtitle" v-if="notice.description_es!=null">
                {{$i18n.locale!='en'
                    ? getShortDescription(notice.description_es)
                    : getShortDescription(notice.description_en)
                }}</h1>
            <MButtonActionComp
                class="mt-2"
                :text="$t('dataPublic.seeNotice')"
                @click="$emit('click')"
            />
        </div>
    </div>
</template>
<script>
import {
    imgMiniature
} from '../../filters'
import {
    MButtonActionComp
} from '../molecules'
export default {
    components: {
        MButtonActionComp
    },
    filters: {
        imgMiniature
    },
    props: {
        notice: {}
    },
    methods: {
        getShortDescription(description) {
            var limitFeatures = 340
            if (window.innerWidth <= 650) limitFeatures = 150
            if (description.length > limitFeatures) {
                var newDescription = ''
                for (var i = 0; i < (limitFeatures-20); i++)
                    newDescription += description[i]
                newDescription += '....'
                return newDescription;
            }
            return description
        }
    }
}
</script>