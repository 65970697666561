<template>
    <div>
        <v-speed-dial
            v-model="fab"
            :bottom="true"
            :right="true"
            fixed
            :direction="direction"
            :transition="transition"
        >
            <template v-slot:activator>
                <v-btn
                    v-model="fab"
                    color="green"
                    dark
                    fab
                    :ripple="false"
                    small
                >
                <v-icon v-if="fab">
                    mdi-close
                </v-icon>
                <v-icon v-else>
                    mdi-whatsapp
                </v-icon>
                </v-btn>
            </template>
            <div class="comp-btns-floting__person">
                <div class="button-whatsapp__title d-flex justify-center align-center">
                    <v-img
                        src="/imgs/logo_colors.svg"
                        width="65px"
                        max-width="65px"
                        class="ml-2 mr-2"
                    ></v-img>
                    <h1 class="text-whatsapp-title-inverse text-center">
                        Sweet Home
                    </h1>
                </div>
                <div
                    v-for="data in listPersonsWhatsapp"
                    :key="data.name"
                    class="comp-btns-floting__name d-flex"
                    @click="$emit('clickphone', data.phone)"
                >
                    <div class="mr-1">
                        <v-icon color="#E1E6E6">mdi-whatsapp</v-icon>
                    </div>
                    <div>
                        <h1 class="text-whatsapp-subtitle-inverse">{{data.person}}</h1>
                        <div class="comp-btns-floting__ornament"></div>
                        <h1 class="text-whatsapp-subtitle-inverse">
                            {{$i18n.locale!='es' ? data.profession_en : data.profession_es}}</h1>
                    </div>
                </div>
            </div>
        </v-speed-dial>
    </div>
</template>
<script>
export default {
    props: {
        listPersonsWhatsapp: Array
    },
    data: () => ({
        fab: false,
        tabs: null,
        right: true,
        bottom: true,
        direction: 'top',
        transition: 'slide-y-reverse-transition',
    })
}
</script>
<style>
    .comp-btns-floting__person{
        /*background: red;*/
        width: 280px !important;
        position: absolute;
        bottom: 0px;
        right: 0px;
    }
    .comp-btns-floting__name{
        width: 100%;
        background: #78c77a;
        border: 2px solid #cce2cd;
        box-shadow: 0px 0px 4px #0000008f;
        border-radius: 5px;
        margin-bottom: 7px;
        padding: 5px;
        cursor: pointer;
    }
    .comp-btns-floting__name:hover{
        border: 2px solid #006105ad;
    }
    .comp-btns-floting__ornament{
        width: 100%;
        height: 2px;
        background: #dde7e2;
    }
    .button-whatsapp__title{
        background: linear-gradient(360deg, rgba(212,86,181,1) 0%, rgba(212,86,181,1) 23%, rgba(180,56,149,1) 100%);
        border-radius: 5px;
        padding: 3px;
        margin-bottom: 15px;
    }
    .button-whatsapp__title::after{
      position: absolute;
      content: '';
      width: 10px;
      height: 10px;
      top: 65px;
      left: 135px;
        border-left: 10px solid #D456B5;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent; 
        transform: rotate(90deg);
    }
</style>