<template>
    <div class="d-flex justify-center">
        <div class="home-public__body animation--opacity">
            <OTitleGalleryComp
                src="/imgs/nest/nest.svg"
                :title="$t('dataPublic.titleGallery')"
            />
            <div class="text-center gallery__empty-list pt-3" v-if="loaderData">
                <v-progress-circular
                indeterminate
                color="rgba(0,0,0,0.5)"
                ></v-progress-circular>
            </div>
            <div v-else>
                <div class="d-flex justify-center flex-wrap pt-2">
                    <OCardImageComp
                        v-for="(photography, index) in listGallery"
                        :key="index"
                        :photography="photography"
                        class="ma-2"
                        @click="ac_showImage(photography, index)"
                    />
                </div>
                <div
                    v-if="listGallery.length==0"
                    style="min-height:380px;"
                >
                    <MTitleNoData
                        message="Sin Imágenes subidas"
                        class="mb-2"
                    />
                </div>
            </div>
            <OPaginationComp
                v-if="listGallery.length > 0 && !loaderData"
                :goCondition="page < last_page"
                :backCondition="page > 1"
                class="mt-2 mb-5"
                :titlePagination="'Pag. '+page+' de '+last_page"
                @clickback="ac_changePage(false)"
                @clickgo="ac_changePage(true)"
            />
        </div>
        <v-dialog
            v-model="dialogImages"
            :width="ac_getWidthImg()"
            persistent
            style="position:relative"
        >
            <div class="gallery__title d-flex">
                <h1 class="text-body-subtitle-inverse">{{getHeaderShow()}}</h1>
                <v-spacer></v-spacer>
                <div class="d-flex">
                    <v-menu
                        transition="slide-x-transition"
                        offset-y
                        :open-on-hover="true"
                    >
                        <template v-slot:activator="{on, attrs}">
                            <v-btn
                                color="primary"
                                depressed
                                fab
                                x-small
                                v-bind="attrs"
                                v-on="on"
                                :ripple="false"
                                @click="ac_download()"
                            >
                                <v-icon small>mdi-download</v-icon>
                            </v-btn>
                        </template>
                        <v-list class="pa-0" style="background:blue">
                            <v-list-item class="pa-0 pl-1 pr-1">
                                <h1 class="text-body-subtitle-inverse ml-1">Descargar</h1>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                    <v-btn
                        x-small
                        color="white"
                        fab
                        class="ml-2"
                        depressed
                        :ripple="false"
                        @click="dialogImages=false"
                    >
                        <v-icon color="black">mdi-close</v-icon>
                    </v-btn>
                </div>
            </div>
            <div class="gallery__carrusel">
                <v-carousel
                    hide-delimiters
                    height="100%"
                    v-model="positionCarrusel"
                >
                    <v-carousel-item
                        v-for="(photography, i) in listGallery"
                        :key="i"
                    >
                        <v-img
                            v-if="imgReal"
                            :src="photography.route_photo|imgReal"
                            contain
                            height="100%"
                        >
                        </v-img>
                        <v-img
                            v-else
                            :src="photography.route_photo|imgMiniature"
                            height="100%"
                            contain
                        ></v-img>
                    </v-carousel-item>
                </v-carousel>
            </div>
        </v-dialog>
    </div>
</template>
<script>
import {
    imgMiniature,
    imgReal
} from '../../filters'
import { MTitleNoData } from '../../components/molecules'
import {
    OCardImageComp,
    OPaginationComp,
    OTitleGalleryComp
} from '../../components/organisms'
import { DataPublic, Photography } from '../../models'
import { animationScrollMixin } from '../../mixins'
export default {
    mixins: [ animationScrollMixin ],
    filters: {
        imgReal,
        imgMiniature
    },
    components: {
        OCardImageComp,
        OPaginationComp,
        OTitleGalleryComp,
        MTitleNoData
    },
    data: () => ({
        dataTitleServices: {
            icon: 'mdi-picture',
            title: 'Galería de Imágenes'
        },
        listGallery: [],
        page: 0,
        last_page: 0,
        dialogImages: false,
        imgReal: true,
        positionCarrusel: 0,
        loaderData: true,
        activateImg: false,
        photo: {}
    }),
    methods: {
        getGallery(page) {
            this.loaderData = true
            DataPublic.getGalleries(page).then(response => {
                this.listGallery = response.data
                this.page = response.current_page
                this.last_page = response.last_page
                this.loaderData = false
            }).catch(error => {
                console.log(error)
            })
        },
        getHeaderShow() {
            if (this.listGallery.length > 0 && this.activateImg) {
                var title = this.listGallery[this.positionCarrusel].description_es
                if (this.$i18n.locale == 'en') title = this.listGallery[this.positionCarrusel].description_en
                return title
            } else return ''
        },
        existHeader() {
            if (this.listGallery.length > 0) {
                if (this.listGallery[this.positionCarrusel].description != null) return true
            }
            return false
        },
        ac_showImage(photography, position) {
            this.photo = photography
            this.activateImg = true
            this.positionCarrusel = position
            setTimeout(() => {
                this.dialogImages = true
            }, 100)
        },
        ac_getWidthImg() {
            if (window.innerWidth <= 1400) return '850'
            else return '1200'
        },
        ac_changePage(state) {
            this.activateImg = false
            const time = this.m_scroll_time(window.scrollY)
            this.m_scroll_animation(time)
            setTimeout(() => {
                this.listGallery = []
                if (state) this.page++
                else this.page--
                this.getGallery(this.page)
            }, time)
        },
        ac_download() {
            Photography.downloadImage(this.photo.route_photo)
        }
    },
    mounted() {
        this.getGallery(1)
    }
}
</script>