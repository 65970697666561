import Vue from 'vue'
import VueRouter from 'vue-router'
import i18n from '../i18n'
import { animationScrollMixin } from '../mixins'

import {
  Home,
  AttentionSchedule,
  Phones,
  Account,
  Photographs,
  Notices
} from '../views/web_site'

import {
  HomePublic,
  Nest,
  ChildComunity,
  ChildrenHouses,
  Pikler,
  Atelier,
  Psychomotricity,
  Nook,
  About,
  Bilingualism,
  Montessori,
  CentralChrist,
  Gallery,
  News,
  Contact,
  ParkAndCourt,
  YardAndGarden,
  Nutrition,
  School,
  Publication
} from '../views/public'

import AppPublic from '../views/public/AppPublic';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: `/${i18n.locale}/home`
  },
  /*{
    path: '/es',
    redirect: `/${i18n.locale}/home`
  },*/
  {
    path: '/website',
    redirect: '/website/home-admin'
  },
  {
    path: '/login',
    redirect: '/login/access'
  },
  {
    path: '/:lang',
    //component: () => import('./views/AppPublic'),
    component: {
      render (c) { return c(AppPublic) }
    },
    children: [
      {
        path: '/:lang/home',
        name: 'homePublic',
        component: HomePublic
      }, {
        path: '/:lang/nest',
        name: 'nest',
        component: Nest
      }, {
        path: '/:lang/child-comunity',
        name: 'child-comunity',
        component: ChildComunity
      }, {
        path: '/:lang/childrens-houses',
        name: 'childrens-houses',
        component: ChildrenHouses
      }, {
        path: '/:lang/pikler',
        name: 'pikler',
        component: Pikler
      }, {
        path: '/:lang/atelier',
        name: 'atelier',
        component: Atelier
      }, {
        path: '/:lang/psychomotricity',
        name: 'psychomotricity',
        component: Psychomotricity
      }, {
        path: '/:lang/nook',
        name: 'nook',
        component: Nook
      }, {
        path: '/:lang/about',
        name: 'about',
        component: About
      }, {
        path: '/:lang/bilingualism',
        name: 'bilingualism',
        component: Bilingualism
      }, {
        path: '/:lang/montessori',
        name: 'montessori',
        component: Montessori
      }, {
        path: '/:lang/central-christ',
        name: 'central-christ',
        component: CentralChrist
      }, {
        path: '/:lang/gallery',
        name: 'gallery',
        component: Gallery
      }, {
        path: '/:lang/news',
        name: 'news',
        component: News
      }, {
        path: '/:lang/contact',
        name: 'contact',
        component: Contact
      }, {
        path: '/:lang/park-and-court',
        name: 'park-and-court',
        component: ParkAndCourt
      }, {
        path: '/:lang/yard-and-garden',
        name: 'yard-and-garden',
        component: YardAndGarden
      }, {
        path: '/:lang/nutrition',
        name: 'nutrition',
        component: Nutrition
      }, {
        path: '/:lang/school',
        name: 'school',
        component: School
      }, {
        path: '/:lang/publication/:id',
        name: 'publication',
        component: Publication
      }
    ]
  },
  {
    path: '/website',
    component: () => import('../views/web_site/AppAdminWeb'),
    children: [
      {
        path: '/website/home-admin',
        name: 'homeAdmin',
        component: Home
      },{
        path: '/website/timetable',
        name: 'timetable',
        component: AttentionSchedule
      },{
        path: '/website/phones',
        name: 'phones',
        component: Phones
      },{
        path: '/website/account',
        name: 'account',
        component: Account
      },{
        path: '/website/photographs',
        name: 'photographs',
        component: Photographs
      },{
        path: '/website/notices',
        name: 'notices',
        component: Notices
      }
    ]
  },
  {
    path: '/login/access',
    component: () => import('../views/login/Login')
  }
]
const router = new VueRouter({
  mode: 'history',
  routes
})
router.beforeEach((to, from, next) => {
  const time = animationScrollMixin.methods.m_scroll_time(window.scrollY)
  animationScrollMixin.methods.m_scroll_animation(time)
  setTimeout(() => {
    next();
  }, time)
})
export default router
