<template>
    <div class="d-flex">
        <v-app-bar
            fixed
            class="menu-mobile-public"
            height="54"
        >
            <div class="d-flex align-center" style="width:100%">
                <router-link :to="{name: 'homePublic'}" style="text-decoration:none !important">
                    <div class="o-menu-public__logo">
                        <div class="o-menu-public__logo-house">
                            <v-img
                                src="/imgs/logo_colors.svg"
                                width="100%"
                                height="100%"
                            ></v-img>
                        </div>
                        <h1 class="text-menu-sweet mr-2 ml-2 mt-1">Sweet</h1>
                        <h1 class="text-menu-home mt-1">Home</h1>
                    </div>
                </router-link>
                <v-spacer></v-spacer>
                <v-menu
                    transition="slide-x-transition"
                    offset-y
                    content-class="menu-mobile-public__schedule"
                >
                    <template v-slot:activator="{on, attrs}">
                        <v-btn
                            :ripple="false"
                            fab
                            x-small
                            color="#269490"
                            depressed
                            height="27"
                            max-height="27"
                            max-width="27"
                            class="mr-2"
                            v-bind="attrs"
                            v-on="on"
                        >
                            <v-icon small color="white">mdi-calendar-clock</v-icon>
                        </v-btn>
                    </template>
                    <div class="pt-1">
                        <div class="button-schedule__hours pa-2">
                            <h1 class="text-body-title-inverse text-center">{{$t('dataPublic.schedule')}}</h1>
                            <div
                                class="d-flex justify-center"
                                v-for="day in listSchedule"
                                :key="day.id"
                            >
                                <div class="d-flex">
                                    <div style="width:96px; min-width:96px;" class="mr-2">
                                        <h1 class="text-body-subtitle-inverse text-right">
                                            {{$i18n.locale!='es' ? day.day_en+':' : day.day_es+':'}}</h1>
                                    </div>
                                    <h1 class="text-body-subtitle-inverse">
                                        {{day.schedule}}</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </v-menu>
                <v-btn
                    :ripple="false"
                    fab
                    x-small
                    depressed
                    height="27"
                    max-height="27"
                    max-width="27"
                    width="27"
                    color="#F7096B"
                    class="mr-2"
                    @click="ac_openInstagram()"
                >
                    <v-icon small color="white">mdi-instagram</v-icon>            
                </v-btn>
                <v-menu
                    transition="slide-x-transition"
                    offset-y
                >
                    <template v-slot:activator="{on, attrs}">
                        <v-btn
                            :ripple="false"
                            fab
                            x-small
                            color="#1294F0"
                            depressed
                            height="27"
                            max-height="27"
                            max-width="27"
                            class="mr-2"
                            v-bind="attrs"
                            v-on="on"
                        >
                            <v-icon small color="white">mdi-facebook</v-icon>
                        </v-btn>
                    </template>
                    <v-list class="pa-0 ma-0 pb-1 pt-1 menu-computer__list">
                        <v-list-item-group
                            color="primary"
                        >
                            <v-list-item
                                class="ma-1 info menu-computer__submenu-item d-flex"
                                @click="ac_openFacebook()"
                            >
                                <h1 class="text-title-inverse">
                                    {{$t('dataPublic.titleFaceOne')}}</h1>
                            </v-list-item>
                            <v-list-item
                                class="ma-1 info menu-computer__submenu-item d-flex"
                                @click="ac_shareFacebook()"
                            >
                                <h1 class="text-title-inverse">
                                {{$t('dataPublic.titleFaceTwo')}}</h1>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </v-menu>
                <v-menu
                    transition="slide-x-transition"
                    offset-y
                >
                    <template v-slot:activator="{on, attrs}">
                        <v-btn
                            :ripple="false"
                            fab
                            x-small
                            color="white"
                            depressed
                            height="27"
                            max-height="27"
                            max-width="27"
                            class="mr-3"
                            v-bind="attrs"
                            v-on="on"
                        >
                            <v-img
                                :src="$i18n.locale!='es' ? '/imgs/flags/eeuu.png' : '/imgs/flags/bolivia.png'"
                                width="27"
                                height="27"
                                contain
                            ></v-img>
                        </v-btn>
                    </template>
                    <v-list class="pa-0 ma-0 pb-1 pt-1 menu-computer__list">
                        <v-list-item-group
                            color="primary"
                        >
                            <v-list-item
                                class="ma-1 menu-computer__submenu-item d-flex"
                                @click="changeLanguage('es')"
                            >
                                <v-img
                                    src="/imgs/flags/bolivia.png"
                                    width="23"
                                    max-width="23"
                                    height="23"
                                ></v-img>
                            </v-list-item>
                            <v-list-item
                                class="ma-1 menu-computer__submenu-item d-flex"
                                @click="changeLanguage('en')"
                            >
                                <v-img
                                    src="/imgs/flags/eeuu.png"
                                    width="23"
                                    max-width="23"
                                    height="23"
                                ></v-img>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </v-menu>
                <v-btn
                    :ripple="false"
                    :retain-focus-on-click="false"
                    icon
                    depressed
                    height="32"
                    max-height="32"
                    max-width="32"
                    width="32"
                    min-width="32"
                    @click="drawer=true"
                >
                    <v-icon style="font-size:28px" color="#707070">mdi-menu</v-icon>            
                </v-btn>
            </div>
        </v-app-bar>
        <v-navigation-drawer
                v-model="drawer"
                fixed
                temporary
                width="360"
        >
            <v-toolbar class="menu-mobile-public__header">
                <div class="d-flex align-center">
                    <v-img
                        src="/imgs/logo_colors_inverse.svg"
                        width="40px"
                        class="mr-3"
                    ></v-img>
                    <h1 class="text-whatsapp-title-inverse mt-1">Sweet Home</h1>
                </div>
                <v-spacer></v-spacer>
                <v-icon @click="drawer=!drawer" color="white">
                    mdi-close-circle
                </v-icon>
            </v-toolbar>
            <v-list nav class="pb-0 pt-2 pb-1">
                <v-list-item-group
                    color="primary"
                >
                    <v-list-item
                        :to="{name: 'homePublic'}"
                        @click="drawer=!drawer"
                        :ripple="false"
                    >
                        <v-list-item-icon class="mr-1">
                            <v-icon small color="secondary">mdi-home</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>
                            <h1 class="text-menu-title">{{$t('dataPublic.titleMenuMobile')}}</h1>
                        </v-list-item-title>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
            <v-list nav class="pb-0 pt-0">
                <div
                    v-for="(option, index) in listMenu"
                    :key="index"
                ><!--v-model="item.active"-->
                    <v-list-group
                        v-if="option.submenu.length>0"
                        :ripple="false"
                        class="mb-1"
                        color="primary"
                    >
                        <template v-slot:activator>
                            <v-list-item-icon class="mr-1">
                                <v-icon small color="secondary">{{option.icon}}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>
                                <h1 class="text-menu-title">{{option.title}}</h1>
                            </v-list-item-title>
                        </template>
                        <v-list-item
                            v-for="(child, index) in option.submenu"
                            :key="index"
                            class="pl-8"
                            :to="{name: child.route}"
                            @click="drawer=!drawer"
                            :ripple="false"
                        >
                            <v-list-item-icon class="mr-1">
                                <v-icon small color="secondary">{{child.icon}}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title class="d-flex align-center">
                                <h1 class="text-menu-title">{{child.title}}</h1>
                            </v-list-item-title>
                        </v-list-item>
                    </v-list-group>
                    <v-list-item-group
                        v-else
                        color="primary"
                    >
                        <v-list-item
                            :to="{name: option.route}"
                            @click="drawer=!drawer"
                            class="mb-1"
                            :ripple="false"
                        >
                            <v-list-item-icon class="mr-1">
                                <v-icon small color="secondary">{{option.icon}}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>
                                <h1 class="text-menu-title">{{option.title}}</h1>
                            </v-list-item-title>
                        </v-list-item>
                    </v-list-item-group>
                </div>
            </v-list>
        </v-navigation-drawer>
    </div>
</template>
<script>
export default {
    props: {
        listMenu: Array,
        listSchedule: Array
    },
    data: () => ({
        drawer: false
    }),
    methods: {
        changeLanguage(language) {
            this.$i18n.locale = language;
            this.$router.push({
                params:{lang: language}
            })
        },
        ac_openFacebook() {
            window.open('https://www.facebook.com/jardininfantilsweethomelp/')
        },
        ac_openInstagram() {
            window.open('https://www.instagram.com/sweet_homebolivia/?igshid=YmMyMTA2M2Y%3D')
        },
        ac_shareFacebook() {
            window.open(`https://sweethomebolivia.com/api/public/send-open-graps-home?lang=${this.$i18n.locale}`, '', "width=600,height=600")
        }
    }
}
</script>
