<template>
    <v-dialog persistent :value="dialog" max-width="380">
        <v-card class="pa-0">
            <div style="background:#196fa0" class="d-flex justify-center pa-1">
                <h1 style="color:white; letter-spacing:1px;" class="text-body-title-inverse">CONFIRMACIÓN</h1>
            </div>
            <div class="mb-2 pt-3 d-flex justify-center">
                <v-icon x-large class="animation-icon--rotate" color="#196fa0">mdi-information</v-icon>
            </div>
            <v-card-text class="pa-1 d-block">
                <h1
                    class="text-center text-body-subtitle"
                    v-for="(error, index) in smsConfirm" :key="index"
                >
                    {{error}}
                </h1>
            </v-card-text>
            <v-card-actions class="justify-center pb-3">
                <v-btn small outlined color="#ff0000b0" @click="$emit('clickno')">NO</v-btn>
                <v-btn small outlined color="#008000b6" @click="$emit('clickyes')">SI</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
export default {
    props: {
        dialog: Boolean,
        smsConfirm: Array
    }
}
</script>
