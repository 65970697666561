<template>
    <div class="o-card-shortcut">
        <div class="o-card-shortcut__img">
            <v-img
                :src="shortcut.icon"
                width="100%"
                height="100%"
            ></v-img>
        </div>
        <div
            class="o-card-shortcut__text"
            :style="`background: ${shortcut.coloBackground}`"
        >
            <h1 class="text-subtitle-inverse text-center">
                {{shortcut.text}}
            </h1>
            <div class="d-flex justify-center mt-2">
                <MButtonActionComp
                    color="white"
                    :outlined="true"
                    :text="shortcut.textBtn"
                    :depressed="true"
                    :to="to"
                />
            </div>
            <div class="o-card-shortcut__text-ornament">
                <v-icon color="#ffffff94">mdi-cards-heart</v-icon>
            </div>
            <div class="o-card-shortcut__text-ornament-two">
                <v-icon color="#ffffff94">mdi-cards-heart</v-icon>
            </div>
        </div>
    </div>
</template>
<script>
import { MButtonActionComp } from '../molecules'
export default {
    components: {
        MButtonActionComp
    },
    props: {
        shortcut: {},
        to: [String, Object]
    }
}
</script>