<template>
    <div class="animation--opacity">
        <div class="d-flex justify-center">
            <div class="home-public__background">
                <v-img
                    src="/imgs/foto.png"
                    width="100%"
                    height="100%"
                ></v-img>
                <div class="home-public__imgs">
                    <v-carousel
                        cycle
                        height="100%"
                        hide-delimiter-background
                        show-arrows-on-hover
                        interval="3000"
                        hide-delimiters
                    >
                        <v-carousel-item
                            v-for="(srcImg, i) in listImgs"
                            :key="i"
                        >
                            <v-img
                                :src="srcImg"
                                width="100%"
                                height="100%"
                            ></v-img>
                        </v-carousel-item>
                    </v-carousel>
                </div>
                <div class="home-public__img-ornament-one" >
                    <v-img
                        src="/imgs/heart/heart.svg"
                        width="100%"
                        height="100%"
                    ></v-img>
                </div>
                <div class="home-public__img-ornament-two" >
                    <v-img
                        src="/imgs/heart/heart_two.svg"
                        width="100%"
                        height="100%"
                    ></v-img>
                </div>
                <div class="home-public__background-text">
                    <h1>Your Future</h1>
                    <h2>BEGINS HERE</h2>
                </div>
            </div>
        </div>
        <div class="d-flex justify-center space-top space-bottom">
            <div class="home-public__body">
                <div class="d-flex justify-center">
                    <div class="home-public__logo-title">
                        <v-img
                            src="/imgs/logo_colors.svg"
                            width="100%"
                            height="100%"
                        ></v-img>
                    </div>
                    <h1 class="text-institution-sweet mr-2">
                        {{$t('dataHomePublic.dataFirstText.title')}}
                    </h1>
                    <h1 class="text-institution-home">
                        {{$t('dataHomePublic.dataFirstText.titleTwo')}}
                    </h1>
                </div>
                <div class="home-public__logo">
                    <div class="home-public__logo-ornament">
                        <h1 class="text-sms-home text-center">
                            {{$t('dataHomePublic.dataFirstText.text')}}
                            <span style="color:#054D4D; font-weight:500;">
                            {{$t('dataHomePublic.dataFirstText.textTwo')}}
                            </span>
                        </h1>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex justify-center">
            <div class="d-flex justify-center flex-wrap home-public__short-cup">
                <OCardShortcutComp
                    v-for="(shortcut, index) in $t('dataHomePublic.dataEducation')"
                    :key="index"
                    :shortcut="shortcut"
                    :to="{name: shortcut.route}"
                />
            </div>
        </div>
        <div class="d-flex justify-center space-top-home">
            <div class="home-public__body">
                <div class="home-public__training">
                    <div class="home-public__training-img">
                        <v-img
                            src="/imgs/home/fond_pictures.png"
                            width="100%"
                            height="100%"
                        >
                        </v-img>
                    </div>
                    <div class="home-public__training-text">
                        <div>
                            <div class="d-flex align-center mb-3">
                                <v-img
                                    src="/imgs/logos/house.svg"
                                    width="55px"
                                    max-width="55px"
                                    class="mr-3"
                                ></v-img>
                                <h1 class="text-title-header mt-3">
                                    {{$t('dataHomePublic.dataTraining.title')}}
                                </h1>
                            </div>
                            <h1
                                v-for="(text, index) in $t('dataHomePublic.dataTraining.text')"
                                :key="index"
                                class="text-subtitle mt-2">
                                {{text}}
                            </h1>
                            <div class="home-public__training-ornament"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex justify-center space-top-home space-bottom-home">
            <div class="home-public__body d-flex">
                <div class="home-public__picture">
                    <div class="home-public__picture-card" style="border: 10px solid #E073C4;">
                        <div class="home-public__picture-card-border">
                            <div class="d-flex align-center justify-center">
                                <v-img
                                    src="/imgs/logos/house.svg"
                                    width="40px"
                                    max-width="40px"
                                    height="40px"
                                    max-height="40px"
                                    class="mr-3"
                                ></v-img>
                                <h1 class="text-title-header text-center mt-2">
                                    {{$t('dataHomePublic.dataPictureOne.title')}}
                                </h1>
                            </div>
                            <div class="home-public__picture-card-img">
                                <v-img
                                    src="/imgs/home/circle_1.jpg"
                                    width="100%"
                                    height="100%"
                                ></v-img>
                            </div>
                            <div class="home-public__picture-ornament"></div>
                            <h1 class="text-subtitle">
                                {{$t('dataHomePublic.dataPictureOne.text')}}
                            </h1>
                            <MButtonActionComp
                                :text="$t('dataHomePublic.titleBtn')"
                                :depressed="true"
                                color="#f3d1e9"
                                class="mt-2"
                                :to="{name: 'nutrition'}"
                            />
                        </div>
                    </div>
                    <div class="home-public__picture-card" style="border: 10px solid #c95aad;">
                        <div class="home-public__picture-card-border">
                            <div class="d-flex align-center justify-center">
                                <v-img
                                    src="/imgs/logos/house.svg"
                                    width="40px"
                                    max-width="40px"
                                    height="40px"
                                    max-height="40px"
                                    class="mr-3"
                                ></v-img>
                                <h1 class="text-title-header text-center mt-1">
                                    {{$t('dataHomePublic.dataPictureTwo.title')}}
                                </h1>
                            </div>
                            <div class="home-public__picture-card-img">
                                <v-img
                                    src="/imgs/home/circle_2.jpg"
                                    width="100%"
                                    height="100%"
                                ></v-img>
                            </div>
                            <div class="home-public__picture-ornament"></div>
                            <h1 class="text-subtitle">
                                {{$t('dataHomePublic.dataPictureTwo.text')}}
                            </h1>
                            <MButtonActionComp
                                :text="$t('dataHomePublic.titleBtn')"
                                :depressed="true"
                                color="#f3d1e9"
                                class="mt-2"
                                :to="{name: 'school'}"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {
    OCardShortcutComp
} from '../../components/organisms'
import {
    MButtonActionComp
} from '../../components/molecules'
import {
    SBackgroundPublicComp
} from '../../components/species'
export default {
    components: {
        SBackgroundPublicComp,
        MButtonActionComp,
        OCardShortcutComp
    },
    data: () => ({
        listImgs: [
            `/imgs/home/front_1.jpg`,
            `/imgs/home/front_2.jpg`,
            `/imgs/home/front_3.jpg`,
            `/imgs/home/front_4.jpg`,
            `/imgs/home/front_5.jpg`,
            `/imgs/home/front_6.jpg`
        ]
    })
}
</script>