<template>
    <div>
        <div
            class="d-flex justify-center"
            v-if="publication.title_es!=undefined"
        >
            <div class="publication__container" v-resize="onResize">
                <div v-if="publication.title_es!=undefined">
                    <h1 class="text-title-header publication__padding-left">
                        {{$i18n.locale!='en'
                            ? publication.title_es.toUpperCase()
                            : publication.title_en.toUpperCase()
                        }}</h1>
                    <v-divider></v-divider>
                    <div class="publication__text-subtitle publication__padding-left">
                        <h1 class="text-subtitle">
                            {{$i18n.locale!='en'
                                ? `Publicación: ${publication.date}`
                                : `Publication: ${publication.date}`
                            }}
                        </h1>
                        <div class="publication__text-subtitle-facebook">
                            <MButtonNormalComp
                                :depressed="true"
                                color="#38529A"
                                :dark="true"
                                @click="ac_shareFacebook()"
                            >
                            <v-icon small class="mr-1">mdi-facebook</v-icon>
                            COMPARTIR FACEBOOK</MButtonNormalComp>
                        </div>
                        <div class="publication__text-subtitle-facebook">
                            <MButtonNormalComp
                                :depressed="true"
                                color="#48C456"
                                :dark="true"
                                @click="ac_shareWhatsApp()"
                            >
                            <v-icon small class="mr-1">mdi-whatsapp</v-icon>
                            COMPARTIR WHATSAPP</MButtonNormalComp>
                        </div>
                    </div>
                    <div :style="ac_getDimensionsImg">
                        <v-img
                            v-if="$i18n.locale!='en'"
                            :src="publication.route_photo_es|imgReal"
                            width="100%"
                        ></v-img>
                        <v-img
                            v-else
                            :src="publication.route_photo_en|imgReal"
                            width="100%"
                        ></v-img>
                    </div>
                    <div class="just-line-break mt-3 publication__padding-left">
                        <h1 class="text-subtitle">
                            {{$i18n.locale!='en'
                                ? publication.description_es
                                : publication.description_en
                            }}
                        </h1>
                    </div>
                </div>
                <div class="publication__social-media-mobile">
                    <MButtonNormalComp
                        :depressed="true"
                        color="#38529A"
                        class="mr-2"
                        :dark="true"
                        @click="ac_shareFacebook()"
                    >
                    <v-icon small class="mr-1">mdi-facebook</v-icon>
                    COMPARTIR</MButtonNormalComp>
                    <MButtonNormalComp
                        :depressed="true"
                        color="#48C456"
                        :dark="true"
                        @click="ac_shareWhatsApp()"
                    >
                    <v-icon small class="mr-1">mdi-whatsapp</v-icon>
                    COMPARTIR</MButtonNormalComp>
                </div>
                <div v-if="listRecommendation.length>0" class="space-bottom">
                    <div class="o-header-view__info-line mt-3 mb-3"></div>
                    <div class="publication__padding-left">
                        <h1 class="text-title-header space-top-two">Otras Publicaciones</h1>
                    </div>
                    <div class="publication__row-recommendation">
                        <div
                            v-for="(publicity, index) in listRecommendation"
                            :key="index"
                            class="publication__recommendation"
                            @click="ac_changePublication(publicity)"
                        >
                            <div class="publication__recommendation-info" v-if="mobile">
                                <h1 class="text-title mb-1">
                                    {{$i18n.locale!='en'
                                        ? publicity.title_es
                                        : publicity.title_en
                                    }}
                                </h1>
                            </div>
                            <div class="publication__recommendation-img">
                                <v-img
                                    v-if="$i18n.locale!='en'"
                                    :src="publicity.route_photo_es|imgMiniature"
                                    width="100%"
                                    height="100%"
                                ></v-img>
                                <v-img
                                    v-else
                                    :src="publicity.route_photo_en|imgMiniature"
                                    width="100%"
                                    height="100%"
                                ></v-img>
                            </div>
                            <div class="publication__recommendation-info">
                                <h1 class="text-title mt-1" v-if="!mobile">
                                    {{$i18n.locale!='en'
                                        ? publicity.title_es
                                        : publicity.title_en
                                    }}
                                </h1>
                                <v-divider v-if="publicity.description_es!=null"></v-divider>
                                <h1 class="text-subtitle mt-1">
                                    {{$i18n.locale!='en'
                                        ? ac_getDescription(publicity.description_es)
                                        : ac_getDescription(publicity.description_en)
                                    }}
                                </h1>
                                <MButtonNormalComp
                                    v-if="mobile"
                                    :depressed="true"
                                    color="primary"
                                    class="mt-1"
                                    :dark="true"
                                    :outlined="true"
                                    @click="ac_changePublicationMobile(publicity)"
                                >
                                {{$t('dataPublic.seeNotice')}}</MButtonNormalComp>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else class="space-bottom"></div>
            </div>
        </div>
        <div v-else style="min-height:600px"></div>
        <OLoaderOperationComp
            :dialog="dialogOperation"
            :message="$i18n.locale!='en'
                ? 'Obteniendo Publicación'
                : 'Getting Publication'"
        />
    </div>
</template>
<script>
import { MButtonNormalComp } from '../../components/molecules'
import { OLoaderOperationComp } from '../../components/organisms'
import { Notice } from '../../models'
import { imgReal, imgMiniature } from '../../filters'
export default {
    filters: {
        imgReal, imgMiniature
    },
    components: {
        OLoaderOperationComp,
        MButtonNormalComp
    },
    watch: { 
        '$route.params.id': {
            handler: function(id) {
                this.dialogOperation = true
                this.publication = {}
                this.listRecommendation = []
                this.isInitialWidth = true
                setTimeout(() => {
                    this.getRequest(id, 6)
                }, 150)
            },
            deep: true,
            immediate: true
        }
    },
    data: () => ({
        dialogOperation: false,
        publication: {},
        listRecommendation: [],
        isInitialWidth: true,
        initialWidth: window.innerWidth,
        mobile: false,
        lang: ''
    }),
    computed: {
        ac_getDimensionsImg: function() {
            if (this.isInitialWidth) {
                if (window.innerWidth <= 1180) {
                    if (this.$i18n.locale == 'es') {
                        var width = this.publication.width_es
                        var height = this.publication.height_es
                    } else {
                        var width = this.publication.width_en
                        var height = this.publication.height_en
                    }
                    const newHeight = parseInt((window.innerWidth * height) / width)
                    return `width:100%; height:${newHeight}px;`
                } else {
                    const newHeight = parseInt((1180 * height) / width)
                    return `width:100%; height:${newHeight}px;`
                }
            } else return 'width:100%; height:auto;'
        }
    },
    methods: {
        onResize () {
            if (this.initialWidth !== window.innerWidth)
                this.isInitialWidth = false
        },
        getRequest(id, quantity) {
            const publication = Notice.getPublication(id)
            const listRecommendations = Notice.getRecommendations(id, quantity)
            Promise.all([publication, listRecommendations]).then(responses => {
                if (responses[0].exist === 0) this.$router.replace({name: 'news'})
                else this.publication = responses[0].publication
                this.listRecommendation = responses[1]
                this.dialogOperation = false
            }).catch(error => {
                console.log(error)
            })
        },
        ac_shareFacebook() {
            window.open(`https://sweethomebolivia.com/api/publicity/send-open-graps?id=${this.publication.id}&lang=${this.$i18n.locale}`, '', "width=600,height=600")
        },
        ac_shareWhatsApp() {
            const linkLocal = `https%3A%2F%2Fwww.sweethomebolivia.com%2F${this.$i18n.locale}%2Fpublication%2F${this.publication.id}`
            if (this.$i18n.locale == 'es')
                var title = this.publication.title_es.toUpperCase()
            else var title = this.publication.title_en.toUpperCase()
            const titleFinal = title.replace(/\s+/g, "+")
            const smsWhatsApp = `https://api.whatsapp.com/send?text=${titleFinal}%0A${linkLocal}`
            window.open(smsWhatsApp)
        },
        ac_getDescription(description) {
            if (description === null) return ''
            else {
                if (description.length <= 100) return description
                else {
                    var newDescription = ''
                    for (var i=0; i < 100; i++) {
                        newDescription += description[i]   
                    }
                    return newDescription+'...'
                }
            }
        },
        ac_changePublication(publication) {
            if (!this.mobile) {
                this.$router.replace({name: 'publication', params: publication})
            }
        },
        ac_changePublicationMobile(publication) {
            this.$router.replace({name: 'publication', params: publication})
        }
    },
    mounted() {
        if (window.innerWidth <= 600) this.mobile = true
        if (this.$route.params.id === undefined) this.$router.replace({name: 'news'})
    }
}
</script>