<template>
    <v-dialog
        persistent
        :value="dialog"
        :width="ac_getWidthImg()"
        style="position:relative"
    >
        <div class="gallery__title d-flex">
            <h1 class="text-body-subtitle-inverse">
                {{$i18n.locale!='es' ? notice.title_en : notice.title_es}}</h1>
            <v-spacer></v-spacer>
            <v-btn
                x-small
                color="white"
                fab
                depressed
                class="ml-2"
                :ripple="false"
                @click="$emit('click')"
            >
                <v-icon color="black">mdi-close</v-icon>
            </v-btn>
        </div>
        <div class="gallery__carrusel" v-if="$i18n.locale!='es'">
            <v-img
                v-if="isImgReal"
                :src="notice.route_photo_en|imgReal"
                contain
                height="100%"
            >
            </v-img>
            <v-img
                v-else
                :src="notice.route_photo_en|imgMiniature"
                height="100%"
                contain
            ></v-img>
            <div class="app-public__btn-notice">
                <MButtonActionComp
                    :dark="true"
                    :text="$t('dataPublic.seeNotice')"
                    @click="$emit('clickGoReport')"
                />
            </div>
        </div>
        <div class="gallery__carrusel" v-else>
            <v-img
                v-if="isImgReal"
                :src="notice.route_photo_es|imgReal"
                contain
                height="100%"
            >
            </v-img>
            <v-img
                v-else
                :src="notice.route_photo_es|imgMiniature"
                height="100%"
                contain
            ></v-img>
            <div class="app-public__btn-notice">
                <MButtonActionComp
                    :dark="true"
                    text="VER NOTICIA"
                    @click="$emit('clickGoReport')"
                />
            </div>
        </div>
    </v-dialog>
</template>
<script>
import { MButtonActionComp } from '../molecules'
import {
    imgMiniature,
    imgReal
} from '../../filters'
export default {
    components: {
        MButtonActionComp
    },
    filters: {
        imgMiniature,
        imgReal
    },
    props: {
        dialog: Boolean,
        notice: {}
    },
    data: () => ({
        isImgReal: true
    }),
    methods: {
        ac_getWidthImg() {
            if (window.innerWidth <= 1400) return '850'
            else return '1200'
        }
    },
    mounted() {
        if (window.innerWidth <= 600) this.isImgReal = false
    }
}
</script>
