<template>
    <div>
        <div class="d-flex justify-center">
            <div class="home-public__body animation--opacity">
                <SDescriptionAboutComp
                    :titleOne="$t('dataAbout.dataMissionVision.titleMission')"
                    :textOne="$t('dataAbout.dataMissionVision.textMission')"
                    srcOne="/imgs/about/mission.jpg"
                    :titleTwo="$t('dataAbout.dataMissionVision.titleVision')"
                    :textTwo="$t('dataAbout.dataMissionVision.textVision')"
                    srcTwo="/imgs/about/vission.jpg"
                />
                <OTitleGalleryComp
                    class="space-top mb-4"
                    src="/imgs/nest/nest.svg"
                    :title="$t('dataAbout.titleValue')"
                />
                <div class="space-bottom">
                    <OInfoValueAboutComp
                        v-for="(info, index) in $t('dataAbout.listOfValours')"
                        :key="index"
                        :info="info"
                        class="mb-2"
                    />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {
    OInfoValueAboutComp,
    OTitleGalleryComp
} from '../../components/organisms'
import { SDescriptionAboutComp } from '../../components/species'
export default {
    components: {
        SDescriptionAboutComp,
        OInfoValueAboutComp,
        OTitleGalleryComp
    }
}
</script>