<template>
    <div class="d-flex justify-center">
        <h1 class="titleNoData">{{message}}</h1>
    </div>
</template>
<script>
export default {
    props: {
        message: String
    }
}
</script>
<style scoped>
    .titleNoData{
        padding: 5px;
        width: 100%;
        margin-left: 10px;
        margin-right: 10px;
        background: #f7e1f0;
        color:rgba(0, 0, 17, 0.774);
        text-align: center;
        border-radius: 5px;
        font-size: 16px;
        opacity: 1;
        /*box-shadow: 0px 0px 6px rgba(2, 16, 63, 0.253);*/
        animation-duration: 1000ms;
        animation-name: animationTitle;
    }
    @media (max-width: 500px) {
        .titleNoData{
            font-size: 14.5px;
        }
    }
    @keyframes animationTitle {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
</style>