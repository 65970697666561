<template>
    <div>
        <div class="d-flex align-center pa-1">
            <div class="d-flex align-center">
                <v-app-bar-nav-icon class="mr-2 attention-schedule__icon-menu" @click="m_menuComp_changeStateMenu()"></v-app-bar-nav-icon>
                <h1 class="text-header">CUENTA</h1>
            </div>
            <v-spacer></v-spacer>
            <OButtonAnimateHeaderComp
                icon="mdi-pencil"
                smsTooltip="Actualizar cuenta"
                class="mr-1"
                @click="dialogForm = true"
            />
            <OButtonCloseSesionComp
                :dataUser="dataUserOld"
                :inAccount="true"
                @click="ac_closedSession()"
            />
        </div>
        <MLineComp />
        <OLoaderDataComp
            v-if="showLoaderData"
            class="mt-3"
            smsLoaderData="Obteniendo mi usuario"
        />
        <div
            v-else
            class="animation--opacity pa-2"
        >
            <div class="d-flex">
                <h1 class="text-body-title">Nombre:</h1>
                <h1 class="text-body-subtitle ml-1">{{dataUserOld.name}}</h1>
            </div>
            <div class="d-flex">
                <h1 class="text-body-title">Apellido Paterno:</h1>
                <h1 class="text-body-subtitle ml-1">{{dataUserOld.last_name}}</h1>
            </div>
            <div class="d-flex">
                <h1 class="text-body-title">Apellido Materno:</h1>
                <h1 class="text-body-subtitle ml-1">{{dataUserOld.mom_last_name}}</h1>
            </div>
            <div class="d-flex">
                <h1 class="text-body-title">Usuario:</h1>
                <h1 class="text-body-subtitle ml-1">{{dataUserOld.username}}</h1>
            </div>
        </div>
        <v-dialog max-width="460px" persistent v-model="dialogForm">
            <v-card>
                <ODialogHeaderComp
                    title="Cambiar Cuenta"
                    @clickclose="ac_closeForm()"
                />
                <v-card-text class="pt-4 pb-0 pl-3 pr-3">
                    <v-form
                        v-on:submit.prevent="ac_sendToConfirmation()"
                        ref="formuser"
                        v-model="valid"
                        lazy-validation
                    >
                        <MTextFieldComp
                            v-model="dataUser.username"
                            label="Usuario"
                            :counter="20"
                            @clickenter="ac_sendToConfirmation()"
                            :rules="[
                                v => !m_rule_emptyField(v) || 'El usuario es requerido',
                                v => !m_rule_withSpaces(v) || 'El usuario no debe contener espacios',
                                v => (v+'').length>=7 || 'El usuario debe contener al menos 7 dígitos',
                                v => (v+'').length<=20 || 'El usuario no debe exeder los 20 dígitos'
                            ]"
                        />
                        <MTextFieldComp
                            v-model="dataUser.password"
                            label="Contraseña"
                            class="mt-2"
                            :icon="activatePassword ? 'mdi-eye' : 'mdi-eye-off'"
                            @clickappend="activatePassword=!activatePassword"
                            :type="activatePassword ? 'text' : 'password'"
                            :rules="[
                                v => !m_rule_emptyField(v) || 'La contraseña es requerido',
                                v => !m_rule_withSpaces(v) || 'La contraseña no debe contener espacios',
                                v => (v+'').length>=7 || 'El usuario debe contener al menos 7 dígitos',
                                v => (v+'').length<=20 || 'El contraseña no debe exeder los 20 dígitos'
                            ]"
                            @clickenter="ac_sendToConfirmation()"
                        />
                        <MTextFieldComp
                            v-model="dataUser.repitPassword"
                            label="Repita Contraseña"
                            class="mt-2"
                            :icon="activatePassword ? 'mdi-eye' : 'mdi-eye-off'"
                            @clickappend="activatePassword=!activatePassword"
                            :type="activatePassword ? 'text' : 'password'"
                            :rules="[
                                v => !m_rule_emptyField(v) || 'La contraseña es requerido',
                                v => !m_rule_withSpaces(v) || 'La contraseña no debe contener espacios',
                                v => (v+'').length>=7 || 'El usuario debe contener al menos 7 dígitos',
                                v => (v+'').length<=20 || 'El contraseña no debe exeder los 20 dígitos',
                                v => (v+'')==dataUser.password || 'Las contraseñas no coinciden'
                            ]"
                            @clickenter="ac_sendToConfirmation()"
                        />
                    </v-form>
                </v-card-text>
                <ODialogActionComp
                    actiontext="GUARDAR"
                    @click="ac_sendToConfirmation()"
                />
            </v-card>
        </v-dialog>
        <v-dialog persistent v-model="dialogRedirect" width="400">
            <v-card class="pa-0">
                <div class="pa-2 primary">
                    <h1 class="text-body-title-inverse text-center">CAMBIO EXITOSO</h1>
                </div>
                <h1 class="text-body-subtitle text-center ma-2">Se cambio los datos de tu cuenta exitosamente,
                    porfavor presiona en aceptar para ingresar con
                    tus nuevas credenciales
                </h1>
                <div class="text-center pa-2">
                    <MButtonActionComp
                        text="ACEPTAR"
                        :outlined="true"
                        @click="ac_redirectLogin()"
                    />
                </div>
            </v-card>
        </v-dialog>
        <OLoaderOperationComp
            :dialog="dialogLoaderOperation"
            :message="smsLoaderOperation"
        />
        <OSmsErrorComp
            :dialog="dialogError"
            :smsError="smsError"
            :actionError="actionError"
            @click="ac_backError()"
        />
        <OSmsConfirmComp
            :dialog="dialogConfirm"
            :smsConfirm="smsConfirm"
            @clickyes="ac_performOperation()"
            @clickno="ac_backError()"
        />
        <OSmsActionComp
            @click="frameCorrect=false"
            :snakCorrecto="frameCorrect"
            :timeout="4000"
            :smsAction="smsAction"
        />
    </div>
</template>
<script>
import { MLineComp, MTextFieldComp, MButtonActionComp } from '../../components/molecules'
import {
    OButtonAnimateHeaderComp,
    OButtonCloseSesionComp,
    OLoaderOperationComp,
    OSmsErrorComp,
    OSmsActionComp,
    OSmsConfirmComp,
    ODialogHeaderComp,
    ODialogActionComp,
    OLoaderDataComp
} from '../../components/organisms'
import { User } from '../../models'
import {
    responseServerMixin,
    rulesMixin,
    saveUserMixin,
    menuCompMixin,
    dataGeneralComponetsMixin
} from '../../mixins'
import md5 from 'md5'
export default {
    components: {
        MLineComp,
        MTextFieldComp,
        MButtonActionComp,

        OButtonAnimateHeaderComp,
        OButtonCloseSesionComp,
        OLoaderOperationComp,
        OSmsErrorComp,
        OSmsActionComp,
        OSmsConfirmComp,
        ODialogActionComp,
        ODialogHeaderComp,
        OLoaderDataComp
    },
    mixins: [
        responseServerMixin,
        rulesMixin,
        saveUserMixin,
        menuCompMixin,
        dataGeneralComponetsMixin
    ],
    data: () => ({
        dataUserOld: {},
        dialogForm: false,
        dataUser: new User(),
        valid: true,
        activatePassword: false,
        dialogRedirect: false
    }),
    methods: {
        processError(error) {
            this.dialogLoaderOperation = false
            this.smsError = this.m_response_smsError(error)
            this.actionError = this.m_response_action(error)
            this.noAccess = this.m_response_autorization(error)
            this.dialogError = true
        },
        getAccount() {
            this.showLoaderData = true
            User.getAccount().then(response => {
                this.dataUserOld = response
                this.dataUser.username = response.username
                this.showLoaderData = false
            }).catch(error => {
                this.processError(error)
            })
        },
        validateForm() {
            var admisiondatos = true
            if (!this.$refs.formuser.validate()) { admisiondatos = false }
            return admisiondatos
        },
        ac_closeForm() {
            this.dialogForm = false
            this.$refs.formuser.resetValidation()
            this.dataUser = new User()
            this.dataUser.username = this.dataUserOld.username
        },
        ac_closedSession() {
            this.smsLoaderOperation = 'Cerrando Sesión'
            this.dialogLoaderOperation = true
            User.closedSession().then(() => {
                this.dialogLoaderOperation = false
                localStorage.removeItem('sweetHome')
                this.$router.replace('/login')
            }).catch(() => {
                this.dialogLoaderOperation = false
                localStorage.removeItem('sweetHome')
                this.$router.replace('/login')
            })
        },
        ac_sendToConfirmation() {
            this.dialogForm = false
            if (this.validateForm()) {
                this.smsConfirm = [`¿Está seguro de cambiar los datos de su cuenta?`]
                this.dialogConfirm = true
            } else {
                this.smsError = ['Revise los datos marcados con rojo, porfavor']
                this.actionError = true
                this.dialogError = true
            }
        },
        ac_backError() {
            if (this.noAccess) {
                localStorage.removeItem('sweetHome')
                this.$router.replace('/login')
            } else {
                this.dialogConfirm = false
                this.dialogError = false
                this.dialogForm = true
            }
        },
        ac_performOperation() {
            const account = {
                'username': this.dataUser.username,
                'password': md5(this.dataUser.password)
            }
            this.dialogConfirm = false
            this.smsLoaderOperation = 'Realizando Operación'
            this.dialogLoaderOperation = true
            User.updateUser(account).then(() => {
                this.dialogLoaderOperation = false
                localStorage.removeItem('sweetHome')
                this.dialogRedirect = true
            }).catch(error => {
                this.processError(error)
            })
        },
        ac_redirectLogin() {
            this.dialogRedirect = false
            setTimeout(() => {
                this.$router.replace('/login')
            }, 250)
        }
    },
    mounted() {
        if (!this.m_saveDataUser()) this.$router.replace('/login')
        else this.getAccount()
    }
}
</script>