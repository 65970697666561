<template>
    <div class="o-footer">
        <div class="o-footer__google-map">
            <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d568.5273229251442!2d-68.0734973090962!3d-16.547252621125143!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x915f219c89ccba11%3A0x9c7e57ca843b92cf!2sSweet%20Home%20Jard%C3%ADn%20Infantil!5e0!3m2!1ses!2sbo!4v1653056663866!5m2!1ses!2sbo"
                :width="300"
                :height="300"
                style="border:0;"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
        </div>
        <div class="o-footer__group-two">
            <div class="o-footer__contact">
                <div class="d-flex mb-1">
                    <h1 class="text-title-inverse">
                        {{$t('dataPublic.location')}}:
                    </h1>
                    <h1 class="text-subtitle-inverse ml-1">La Paz - Bolivia</h1>
                </div>
                <div class="d-flex mb-1">
                    <h1 class="text-title-inverse">
                        {{$t('dataPublic.address')}}:
                    </h1>
                    <h1 class="text-subtitle-inverse ml-1">Av. Costanera #50, esq. calle 5</h1>
                </div>
                <div class="d-flex o-footer__contact-text mb-1">
                    <a href="mailto:raunak.00o201@gmail.com" class="d-flex">
                        <h1 class="text-title-inverse">G-mail:</h1>
                        <h1 class="text-subtitle-inverse ml-1">sweethomebolivia80@gmail.com</h1>
                    </a>
                </div>
                <div class="d-flex o-footer__contact-text" @click="goFace()">
                    <h1 class="text-title-inverse">Facebook:</h1>
                    <h1 class="text-subtitle-inverse ml-1">jardininfantilsweethomelp</h1>
                </div>
            </div>
            <div class="o-footer__info">
                <div class="o-footer__info-services">
                    <h1 class="text-title-inverse mb-1">
                        {{$t('dataFooter.titleServices')}}
                    </h1>
                    <div
                        v-for="(service, index) in $t('dataFooter.listServices')"
                        :key="index"
                        class="d-flex mb-1 o-footer__card-service pl-4"
                    >
                        <router-link
                            :to="{name: service.route}"
                            class="d-flex mb-1"
                            style="text-decoration:none">
                            <v-icon class="mr-1" color="#B1C5C5">{{service.icon}}</v-icon>
                            <h1 class="text-subtitle-inverse">
                                {{service.title}}
                            </h1>
                        </router-link>
                    </div>
                </div>
                <div class="o-footer__info-ornament"></div>
                <div class="o-footer__info-schedules">
                    <h1 class="text-title-inverse">
                        {{$t('dataPublic.schedule')}}
                    </h1>
                    <div class="o-footer__schedules-ornament"></div>
                    <div
                        v-for="day in listSchedule"
                        :key="day.id"
                    >
                        <div class="d-flex">
                            <div style="width:100px; min-width:100px;" class="mr-2">
                                <h1 class="text-subtitle-inverse text-right">
                                    {{$i18n.locale!='es' ? day.day_en+':' : day.day_es+':'}}</h1>
                            </div>
                            <h1 class="text-subtitle-inverse">
                                {{day.schedule}}</h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
    computed: {
        ...mapState('scheduleStore', ['listSchedule'])
    },
}
</script>