<template>
    <v-menu
            v-model="menu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
    >
        <template v-slot:activator="{ on }">
            <v-text-field
                    dense
                    outlined
                    :value="value"
                    @input="(e)=>(this.$emit('input', e))"
                    :label="label"
                    :rules="rules"
                    readonly
                    v-on="on"
            ></v-text-field>
        </template>
        <v-date-picker
                color="#21409A"
                @change="$emit('change', date)"
                locale="es-mx"
                v-model="date"
                @input="menu = false"
        ></v-date-picker>
    </v-menu>
</template>
<script>
export default {
    model: {
        prop: 'value',
        event: 'input'
    },
    props: {
        value: [Number, String],
        rules: Array,
        label: String
    },
    data: () => ({
        menu: false,
        date: null
    })
}
</script>
