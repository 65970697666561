<template>
    <div class="app-public">
        <OMenuPublicComp
            :listMenu="$t('menuList')"
        />
        <OMenuMobilePublicComp
            :listMenu="$t('menuList')"
            :listSchedule="listSchedule"
        />
        <div class="app-public__container">
            <router-view />
        </div>
        <OFooterComp />
        <OButtonWhatsappComp
            v-if="listPersonsWhatsapp.length>0"
            :listPersonsWhatsapp="listPersonsWhatsapp"
            @clickphone="goWhatsapp"
        />
        <OButtonScheduleComp
            v-if="!mobile"
            :listSchedule="listSchedule"
        />
        <ODialogShowNoticeComp
            :dialog="dialogNotice"
            :notice="notice"
            @click="dialogNotice=false"
            @clickGoReport="ac_goPublication()"
        />
        <v-menu
            transition="slide-x-transition"
            offset-y
        >
            <template v-slot:activator="{on, attrs}">
                <div
                    class="app-public__btn-facebook"
                    v-bind="attrs"
                    v-on="on"
                >
                    <v-btn
                        :ripple="false"
                        fab
                        small
                        dark
                        color="#1294F0"
                    >
                        <v-icon>mdi-facebook</v-icon>
                    </v-btn>
                </div>
            </template>
            <v-list class="pa-0 ma-0 pb-1 pt-1 menu-computer__list" style="min-width:180px;">
                <v-list-item-group
                    color="primary"
                >
                    <v-list-item
                        class="ma-1 info menu-computer__submenu-item d-flex"
                        @click="ac_openFacebook()"
                    >
                        <h1 class="text-title-inverse">
                            {{$t('dataPublic.titleFaceOne')}}</h1>
                    </v-list-item>
                    <v-list-item
                        class="ma-1 info menu-computer__submenu-item d-flex"
                        @click="ac_shareFacebook()"
                    >
                        <h1 class="text-title-inverse">
                        {{$t('dataPublic.titleFaceTwo')}}</h1>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
        </v-menu>
        <div class="app-public__btn-scroll">
            <v-btn
                :ripple="false"
                fab
                small
                dark
                color="#1203e7"
                @click="ac_upScroll()"
            >
                <v-icon>mdi-chevron-up</v-icon>            
            </v-btn>
        </div>
        <div class="app-public__btn-instragram">
            <v-btn
                :ripple="false"
                fab
                small
                dark
                color="#F7096B"
                @click="ac_openInstagram()"
            >
                <v-icon>mdi-instagram</v-icon>            
            </v-btn>
        </div>
    </div>
</template>
<script>
import {
    OMenuPublicComp,
    OButtonWhatsappComp,
    OButtonScheduleComp,
    ODialogShowNoticeComp,
    OMenuMobilePublicComp,
    OFooterComp
} from '../../components/organisms'
import {
    DataPublic
} from '../../models'
import { scheduleMixin, animationScrollMixin } from '../../mixins'
import { mapState, mapMutations } from 'vuex'
export default {
    mixins: [ scheduleMixin, animationScrollMixin ],
    components: {
        OMenuPublicComp,
        OButtonWhatsappComp,
        OButtonScheduleComp,
        ODialogShowNoticeComp,
        OMenuMobilePublicComp,
        OFooterComp
    },
    data: () => ({
        listPersonsWhatsapp: [],
        dialogNotice: false,
        notice: {},
        mobile: false
    }),
    computed: {
        ...mapState('scheduleStore', ['listSchedule'])
    },
    methods: {
        ...mapMutations('scheduleStore', ['addSchedule']),
        ac_upScroll() {
            window.scroll({
                top: 0,
                behavior: 'smooth'
            });
        },
        activeNotice(noticesList) {
            if (noticesList.length > 0) {
                if (JSON.parse(localStorage.getItem('noticeSweetHome'))) {
                var getIdsViews = JSON.parse(localStorage.getItem('noticeSweetHome'))
                var newIdsViews = []
                var existNewNotice = false
                var indexNotice = -1
                for (var i = 0; i < noticesList.length; i++) {
                    var existId = getIdsViews.find(element => element === noticesList[i].id)
                    if (existId === undefined) {
                    if (!existNewNotice) {
                        newIdsViews.push(noticesList[i].id)
                        indexNotice = i
                        existNewNotice = true
                    }
                    } else newIdsViews.push(noticesList[i].id)
                }
                localStorage.setItem('noticeSweetHome', JSON.stringify(newIdsViews))
                if (existNewNotice) {
                    this.notice = noticesList[indexNotice]
                    this.dialogNotice = true
                }
                } else {
                var sendIdsViews = [noticesList[0].id]
                localStorage.setItem('noticeSweetHome', JSON.stringify(sendIdsViews))
                this.notice = noticesList[0]
                this.dialogNotice = true
                }
            }
        },
        getData() {
            DataPublic.getData().then(response => {
                this.listPersonsWhatsapp = response.cell_phone_list
                const scheduleList = this.m_schedule_orderDays(response.attention_schedule)
                this.addSchedule(scheduleList)
                this.activeNotice(response.list_notices)
            }).catch(error => {
                console.log(error)
            })
        },
        goWhatsapp(e) {
            setTimeout(() => {
                window.open('https://api.whatsapp.com/send?phone=591'+e)
            }, 250)
        },
        ac_openFacebook() {
            window.open('https://www.facebook.com/jardininfantilsweethomelp/')
        },
        ac_openInstagram() {
            window.open('https://www.instagram.com/sweet_homebolivia/?igshid=YmMyMTA2M2Y%3D')
        },
        ac_goPublication() {
            this.dialogNotice = false
            const time = this.m_scroll_time(window.scrollY)
            this.m_scroll_animation(time)
            setTimeout(() => {
                this.$router.push({name: 'publication', params: {id: this.notice.id}})
            }, time)
        },
        ac_shareFacebook() {
            window.open(`https://sweethomebolivia.com/api/public/send-open-graps-home?lang=${this.$i18n.locale}`, '', "width=600,height=600")
        }
    },
    mounted() {
        if (window.innerWidth <= 600) this.mobile = true
        this.getData()
    }
}
</script>