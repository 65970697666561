import axios from "axios"
import { apiUrls } from "../plugins/apiUrls"
import { headerAxiosStore } from "../store/modules"
export default class Phones {
    constructor(
        person = null,
        phone = null,
        profession_es = null,
        profession_en = null
    ){
        this.person = person
        this.phone = phone
        this.profession_es = profession_es
        this.profession_en = profession_en
    }
    static phoneList() {
        return axios.get(
            apiUrls.PHONE + '/list',
            { headers: headerAxiosStore.state.header }
        ).then(response => {
            return response.data
        })
    }
    static addPhone(parameters) {
        return axios.post(
            apiUrls.PHONE + '/add',
            parameters,
            { headers: headerAxiosStore.state.header }
        ).then(response => {
            return response.data
        })
    }
    static updatePhone(parameters) {
        return axios.put(
            apiUrls.PHONE + '/update',
            parameters,
            { headers: headerAxiosStore.state.header }
        ).then(response => {
            return response.data
        })
    }
    static deletePhone(id) {
        return axios.delete(
            apiUrls.PHONE + '/delete/' + id,
            { headers: headerAxiosStore.state.header }
        ).then(response => {
            return response.data
        })
    }
}