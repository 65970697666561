<template>
    <div class="o-header-about">
        <div class="d-flex align-center">
            <div class="o-header-about__logo">
                <v-img
                    src="/imgs/logos/house.svg"
                    width="100%"
                    height="100%"
                ></v-img>
            </div>
            <div class="o-header-about__text">
                <h1 class="text-title-header">{{title}}</h1>
            </div>
        </div>
        <div class="o-header-about__ornament"></div>
    </div>
</template>
<script>
export default {
    props: {
        title: String
    }
}
</script>