<template>
    <div>
        <v-tooltip bottom color="#ff90f6">
            <template v-slot:activator="{ on, attrs }">
                <v-btn class="primary btn-animation-header-comp"
                    width="40"
                    max-width="40"
                    min-width="40"
                    depressed
                    v-bind="attrs"
                    v-on="on"
                    :ripple="false"
                    @click="$emit('click')"
                >
                    <v-icon class="btn-animation-header-comp__icon">{{icon}}</v-icon>
                </v-btn>
            </template>
            <div class="d-flex align-center">
                <v-icon class="btn-animation-header__icon mr-1" color="black">mdi-hand-pointing-right</v-icon>
                <h1 class="text-body-subtitle">{{smsTooltip}}</h1>
            </div>
        </v-tooltip>
    </div>
</template>
<script>
export default {
    props: {
        icon: String,
        smsTooltip: String
    }
}
</script>
