<template>
    <div>
        <div class="d-flex justify-center">
            <div class="home-public__body animation--opacity">
                <OHeaderViewComp
                    src="/imgs/nutritionAndAfter/icon_nutrition.svg"
                    :title="$t('dataNutrition.headerInfo.title')"
                    :subtitle="$t('dataNutrition.headerInfo.subtitle')"
                />
                <OCourseInformationComp
                    class="space-top space-bottom"
                    :listInfo="$t('dataNutrition.listInfo')"
                    src="/imgs/nutritionAndAfter/nutrition_fond.png"
                />
            </div>
        </div>
    </div>
</template>
<script>
import {
    OHeaderViewComp,
    OCourseInformationComp
} from '../../components/organisms'
export default {
    components: {
        OHeaderViewComp,
        OCourseInformationComp
    }
}
</script>