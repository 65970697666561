import axios from "axios"
import { apiUrls } from "../plugins/apiUrls"
import { headerAxiosStore } from "../store/modules"
export default class Schedule {
    constructor(
        day_es = null,
        day_en = null,
        schedule = null
    ){
        this.day_es = day_es
        this.day_en = day_en
        this.schedule = schedule
    }
    static getScheduleList() {
        return axios.get(
            apiUrls.SCHEDULE + '/list',
            { headers: headerAxiosStore.state.header }
        ).then(
            response => {
                return response.data
            }
        )
    }
    static addSchedule(parameters) {
        return axios.post(
            apiUrls.SCHEDULE + '/add',
            parameters,
            { headers: headerAxiosStore.state.header }
        ).then(response => {
            return response.data
        })
    }
    static updateSchedule(parameters) {
        return axios.put(
            apiUrls.SCHEDULE + '/update',
            parameters,
            { headers: headerAxiosStore.state.header }
        ).then(response => {
            return response.data
        })
    }
    static deleteSchedule(idSchedule) {
        return axios.delete(
            apiUrls.SCHEDULE + '/delete/' + idSchedule,
            { headers: headerAxiosStore.state.header }
        ).then(response => {
            return response.data
        })
    }
}