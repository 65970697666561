<template>
    <div>
        <div class="d-flex align-center pa-1">
            <div class="d-flex align-center">
                <v-app-bar-nav-icon class="mr-2 attention-schedule__icon-menu" @click="m_menuComp_changeStateMenu()"></v-app-bar-nav-icon>
                <h1 class="text-header">TELEFONOS</h1>
            </div>
            <v-spacer></v-spacer>
            <OButtonAnimateHeaderComp
                icon="mdi-plus"
                smsTooltip="Nuevo teléfono"
                class="mr-1"
                @click="ac_openForm(true, {}, -1)"
            />
            <OButtonCloseSesionComp
                :dataUser="dataUser"
                @click="ac_closedSession()"
                @clickaccount="ac_myAccount()"
            />
        </div>
        <MLineComp />
        <OLoaderDataComp
            v-if="showLoaderData"
            class="mt-3"
            smsLoaderData="Obteniendo Teléfonos"
        />
        <MTitleNoData
            message="SIN TÉLEFONOS"
            class="mt-2"
            v-if="!showLoaderData && phoneList.length==0"
        />
        <div class="d-flex justify-center flex-wrap mt-1">
            <div
                class="attention-schedule__box attention-schedule__box--width animation--opacity ma-2"
                v-for="(phone, index) in phoneList"
                :key="index"
            >
                <div class="d-flex">
                    <h1 class="text-body-title">Persona:</h1>
                    <h1 class="text-body-subtitle ml-1">{{phone.person}}</h1>
                </div>
                <div class="d-flex">
                    <h1 class="text-body-title">Teléfono:</h1>
                    <h1 class="text-body-subtitle ml-1">{{phone.phone}}</h1>
                </div>
                <div class="d-flex">
                    <h1 class="text-body-title">Cargo (español):</h1>
                    <h1 class="text-body-subtitle ml-1">{{phone.profession_es}}</h1>
                </div>
                <div class="d-flex">
                    <h1 class="text-body-title">Cargo (inglés):</h1>
                    <h1 class="text-body-subtitle ml-1">{{phone.profession_en}}</h1>
                </div>
                <div class="d-flex justify-center mt-2">
                    <OButtonActionCardComp
                        icon="mdi-pencil"
                        class="mr-1"
                        :smsTooltip="'Editar teléfono de: '+phone.person"
                        @click="ac_openForm(false, phone, index)"
                    />
                    <OButtonActionCardComp
                        icon="mdi-trash-can"
                        :smsTooltip="'Eliminar teléfono de: '+phone.person"
                        @click="ac_confirmDelete(phone)"
                    />
                </div>
            </div>
        </div>
        <v-dialog max-width="460px" persistent v-model="dialogForm">
            <v-card>
                <ODialogHeaderComp
                    :title="indexPhone==-1 ? 'Registrar' : 'Editar'"
                    @clickclose="ac_closeForm()"
                />
                <v-card-text class="pt-4 pb-0 pl-3 pr-3">
                    <v-form
                        v-on:submit.prevent="ac_sendToConfirmation()"
                        ref="formphone"
                        v-model="valid"
                        lazy-validation
                    >
                        <MTextFieldComp
                            v-model="dataPhone.person"
                            label="Persona"
                            :counter="20"
                            @clickenter="ac_sendToConfirmation()"
                            :rules="[
                                v => !m_rule_emptyField(v) || 'El persona es requerida',
                                v => (v+'').length<=20 || 'El nombre no debe exeder los 20 dígitos'
                            ]"
                        />
                        <MTextFieldComp
                            v-model="dataPhone.phone"
                            label="Teléfono"
                            :counter="8"
                            @clickenter="ac_sendToConfirmation()"
                            :rules="[
                                v => !m_rule_emptyField(v) || 'El teléfono es requerido',
                                v => (v+'').length==8 || 'El teléfono debe ser de 8 dígitos'
                            ]"
                        />
                        <MTextFieldComp
                            v-model="dataPhone.profession_es"
                            label="Cargo (Español)"
                            :counter="20"
                            @clickenter="ac_sendToConfirmation()"
                            :rules="[
                                v => !m_rule_emptyField(v) || 'El cargo es requerido',
                                v => (v+'').length<=20 || 'El cargo no debe exeder los 20 dígitos'
                            ]"
                        />
                        <MTextFieldComp
                            v-model="dataPhone.profession_en"
                            label="Cargo (Inglés)"
                            :counter="20"
                            @clickenter="ac_sendToConfirmation()"
                            :rules="[
                                v => !m_rule_emptyField(v) || 'El cargo es requerido',
                                v => (v+'').length<=20 || 'El cargo no debe exeder los 20 dígitos'
                            ]"
                        />
                    </v-form>
                </v-card-text>
                <ODialogActionComp
                    actiontext="GUARDAR"
                    @click="ac_sendToConfirmation()"
                />
            </v-card>
        </v-dialog>
        <OLoaderOperationComp
            :dialog="dialogLoaderOperation"
            :message="smsLoaderOperation"
        />
        <OSmsErrorComp
            :dialog="dialogError"
            :smsError="smsError"
            :actionError="actionError"
            @click="ac_backError()"
        />
        <OSmsConfirmComp
            :dialog="dialogConfirm"
            :smsConfirm="smsConfirm"
            @clickyes="ac_performOperation()"
            @clickno="ac_backError()"
        />
        <OSmsActionComp
            @click="frameCorrect=false"
            :snakCorrecto="frameCorrect"
            :timeout="4000"
            :smsAction="smsAction"
        />
    </div>
</template>
<script>
import {
    MLineComp,
    MTextFieldComp,
    MTitleNoData
} from '../../components/molecules'
import {
    OButtonAnimateHeaderComp,
    OButtonCloseSesionComp,
    OLoaderOperationComp,
    OSmsErrorComp,
    OSmsConfirmComp,
    OSmsActionComp,
    OLoaderDataComp,
    OButtonActionCardComp,
    ODialogHeaderComp,
    ODialogActionComp
} from '../../components/organisms'
import { User, Phone } from '../../models'
import {
    menuCompMixin,
    saveUserMixin,
    dataGeneralComponetsMixin,
    rulesMixin,
    responseServerMixin
} from '../../mixins'
export default {
    components: {
        MLineComp,
        MTextFieldComp,
        MTitleNoData,
        OButtonCloseSesionComp,
        OButtonAnimateHeaderComp,
        OLoaderOperationComp,
        OSmsErrorComp,
        OSmsConfirmComp,
        OSmsActionComp,
        OLoaderDataComp,
        OButtonActionCardComp,
        ODialogActionComp,
        ODialogHeaderComp
    },
    mixins: [
        menuCompMixin,
        saveUserMixin,
        dataGeneralComponetsMixin,
        rulesMixin,
        responseServerMixin
    ],
    data: () => ({
        dataUser: {},
        phoneList: [],
        dataPhone: new Phone(),
        idPhone: -1,
        indexPhone: -1,
        dialogForm: false,
        valid: true
    }),
    methods: {
        processError(error) {
            this.dialogLoaderOperation = false
            this.smsError = this.m_response_smsError(error)
            this.actionError = this.m_response_action(error)
            this.noAccess = this.m_response_autorization(error)
            this.dialogError = true
        },
        getPhones() {
            this.showLoaderData = true
            Phone.phoneList().then(response => {
                this.phoneList = response
                this.showLoaderData = false
            }).catch(error => {
                this.processError(error)
            })
        },
        validateForm() {
            var admisiondatos = true
            if (!this.$refs.formphone.validate()) { admisiondatos = false }
            return admisiondatos
        },
        showSmsAction(sms) {
            this.smsAction = sms
            this.frameCorrect = true
            this.timeSucess = setTimeout(() => {
                this.frameCorrect = false
            }, 4000)
        },
        addPhone() {
            Phone.addPhone(this.dataPhone).then(response => {
                this.phoneList.unshift(response)
                this.showSmsAction(['Registro exitoso', 'Se registró el teléfono de: ' + response.person])
                this.ac_closeForm()
                this.dialogLoaderOperation = false
            }).catch(error => {
                this.processError(error)
            })
        },
        updatePhone() {
            Phone.updatePhone(this.dataPhone).then(response => {
                this.phoneList[this.indexPhone] = response
                this.showSmsAction(['Actualización exitosa',
                    'Se actualizó el teléfono de: ' + response.person])
                this.ac_closeForm()
                this.dialogLoaderOperation = false
            }).catch(error => {
                this.processError(error)
            })
        },
        deletePhone() {
            Phone.deletePhone(this.idPhone).then(response => {
                this.phoneList.splice(this.indexPhone, 1)
                this.showSmsAction(['Eliminación exitosa',
                    'Se eliminó el teléfono de: ' + response.person])
                this.indexPhone = -1
                this.idPhone = -1
                this.dialogLoaderOperation = false
            }).catch(error => {
                this.processError(error)
            })
        },
        //events HTML
        ac_closedSession() {
            this.smsLoaderOperation = 'Cerrando Sesión'
            this.dialogLoaderOperation = true
            User.closedSession().then(() => {
                this.dialogLoaderOperation = false
                localStorage.removeItem('sweetHome')
                this.$router.replace('/login')
            }).catch(() => {
                this.dialogLoaderOperation = false
                localStorage.removeItem('sweetHome')
                this.$router.replace('/login')
            })
        },
        ac_closeForm() {
            this.dialogForm = false
            this.$refs.formphone.resetValidation()
            this.dataPhone = new Phone()
        },
        ac_openForm(isNew, phone, index) {
            this.indexPhone = index
            if (!isNew) this.dataPhone = Object.assign({}, phone)
            this.dialogForm = true
        },
        ac_sendToConfirmation() {
            this.dialogForm = false
            if (this.validateForm()) {
                const smsAction = this.indexPhone === -1 ? 'registrar' : 'actualizar'
                this.smsConfirm = [`¿Está seguro de ${smsAction}`, `el teléfono de: ${this.dataPhone.person}?`]
                this.dialogConfirm = true
            } else {
                this.smsError = ['Revise los datos marcados con rojo, porfavor']
                this.actionError = true
                this.dialogError = true
            }
        },
        ac_backError() {
            if (this.noAccess) {
                localStorage.removeItem('sweetHome')
                this.$router.replace('/login')
            } else {
                this.dialogConfirm = false
                this.dialogError = false
                if (this.idPhone === -1) this.dialogForm = true
                else this.idPhone = -1
            }
        },
        ac_performOperation() {
            this.dialogConfirm = false
            this.smsLoaderOperation = 'Realizando Operación'
            this.dialogLoaderOperation = true
            if (this.idPhone !== -1) {
                this.deletePhone()
            } else {
                if (this.indexPhone !== -1) this.updatePhone()
                else this.addPhone()
            }
        },
        ac_confirmDelete(phone) {
            this.idPhone = phone.id
            this.indexPhone = this.phoneList.indexOf(phone)
            this.smsConfirm = [`¿Está seguro de eliminar`, `el teléfono de: ${phone.person} ?`]
            this.dialogConfirm = true
        },
        ac_myAccount() {
            this.$router.push({
                'name': 'account'
            })
        }
    },
    mounted() {
        if (!this.m_saveDataUser()) {
            this.$router.replace('/login')
        } else {
            this.dataUser = JSON.parse(localStorage.getItem('sweetHome'))
            this.getPhones()
        }
    }
}
</script>