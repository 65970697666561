<template>
    <div class="o-course-information">
        <div class="o-course-information__info">
            <div class="o-course-information__info-container">
                <div
                    class="o-course-information__info-container-text"
                    v-for="(info, index) in listInfo"
                    :key="index"
                >
                    <div class="o-course-information__info-container-circle"></div>
                    <h1 class="text-subtitle-inverse">{{info}}</h1>
                </div>
                <div
                    class="o-course-information__info-logo"
                    v-if="withLogo"
                >
                    <v-img
                        src="/imgs/logo_colors_inverse.svg"
                        width="100%"
                        height="100%"
                    ></v-img>
                </div>
            </div>
        </div>
        <div class="o-course-information__img">
            <v-img
                width="100%"
                height="100%"
                :src="src"
            ></v-img>
            <div class="o-course-information__img-ornament-one" >
                <v-img
                    src="/imgs/heart/heart.svg"
                    width="100%"
                    height="100%"
                ></v-img>
            </div>
            <div class="o-course-information__img-ornament-two" >
                <v-img
                    src="/imgs/heart/heart_two.svg"
                    width="100%"
                    height="100%"
                ></v-img>
            </div>
            <div class="o-course-information__img-ornament-three" >
                <v-img
                    src="/imgs/heart/heart.svg"
                    width="100%"
                    height="100%"
                ></v-img>
            </div>
            <div class="o-course-information__img-ornament-for" >
                <v-img
                    src="/imgs/heart/heart_two.svg"
                    width="100%"
                    height="100%"
                ></v-img>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        listInfo: Array,
        src: String,
        withLogo: {
            type: Boolean,
            default: false
        }
    }   
}
</script>