<template>
    <v-menu
        transition="slide-x-transition"
        offset-y
        right
        content-class="button-close-sesion"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                color="primary"
                width="40"
                max-width="40"
                min-width="40"
                :ripple="false"
                v-bind="attrs"
                v-on="on"
                depressed
            >
                <v-icon>mdi-account</v-icon>
            </v-btn>
        </template>
        <div class="pa-2 pr-4 pl-4 button-close-sesion__body-menu">
            <h1 class="text-center text-body-title mb-2">{{ac_getCompletName()}}</h1>
            <div class="text-center" v-if="!inAccount">
                <v-btn
                    :ripple="false"
                    small
                    depressed
                    class="mb-2"
                    color="white"
                    @click="$emit('clickaccount')"
                >MI CUENTA</v-btn>
            </div>
            <div class="text-center">
                <v-btn
                    :ripple="false"
                    small
                    depressed
                    color="white"
                    @click="$emit('click')"
                >CERRAR SESIÓN</v-btn>
            </div>
        </div>
    </v-menu>
</template>
<script>
export default {
    props: {
        dataUser: {},
        inAccount: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        ac_getCompletName() {
            return `${this.dataUser.name} ${this.dataUser.last_name} ${this.dataUser.mom_last_name}`
        }
    }
}
</script>