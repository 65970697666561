export default {
    methods: {
        m_date_formatDate(date) {
            if (date === null) return null
            const [year, month, day] = date.split("-")
            return `${day}/${month}/${year}`
        },
        m_date_systemDate(type_format) {
            let today = new Date()
            let dd = today.getDate()
            let mm = today.getMonth() + 1
            let yyyy = today.getFullYear()
            if (dd < 10) dd = "0" + dd
            if (mm < 10) mm = "0" + mm
            if (type_format) return (today = dd + "/" + mm + "/" + yyyy)
            return (today = yyyy + "-" + mm + "-" + dd)
        },
        m_date_formatEstandar(date) {
            if (date === null) return null
            const [day, month, year] = date.split("/")
            return `${year}-${month}-${day}`
        }
    }
}
