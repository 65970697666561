<template>
    <div>
        <div class="d-flex align-center pa-2">
            <div class="d-flex align-center">
                <v-app-bar-nav-icon class="mr-2 attention-schedule__icon-menu" @click="m_menuComp_changeStateMenu()"></v-app-bar-nav-icon>
                <h1 class="text-header">HORARIOS</h1>
            </div>
            <v-spacer></v-spacer>
            <OButtonAnimateHeaderComp
                icon="mdi-plus"
                smsTooltip="Nuevo horario"
                class="mr-1"
                @click="ac_openForm(true, {}, -1)"
            />
            <OButtonCloseSesionComp
                :dataUser="dataUser"
                @click="ac_closedSession()"
                @clickaccount="ac_myAccount()"
            />
        </div>
        <MLineComp />
        <OLoaderDataComp
            v-if="showLoaderData"
            class="mt-3"
            smsLoaderData="Obteniendo Horarios"
        />
        <MTitleNoData
            message="SIN HORARIOS"
            class="mt-2"
            v-if="!showLoaderData && scheduleList.length==0"
        />
        <div class="d-flex justify-center flex-wrap mt-1">
            <div
                class="attention-schedule__box attention-schedule__box--width animation--opacity ma-2"
                v-for="(schedule, index) in scheduleList"
                :key="index"
            >
                <div class="d-flex">
                    <h1 class="text-body-title">Día:</h1>
                    <h1 class="text-body-subtitle ml-1">{{schedule.day_es}}</h1>
                </div>
                <div class="d-flex">
                    <h1 class="text-body-title">Horario Atención:</h1>
                    <h1 class="text-body-subtitle ml-1">{{schedule.schedule}}</h1>
                </div>
                <div class="d-flex justify-center mt-2">
                    <OButtonActionCardComp
                        icon="mdi-pencil"
                        class="mr-1"
                        :smsTooltip="'Editar día: '+schedule.day_es"
                        @click="ac_openForm(false, schedule, index)"
                    />
                    <OButtonActionCardComp
                        icon="mdi-trash-can"
                        :smsTooltip="'Eliminar día: '+schedule.day_es"
                        @click="ac_confirmDelete(schedule)"
                    />
                </div>
            </div>
        </div>
        <v-dialog max-width="460px" persistent v-model="dialogForm">
            <v-card>
                <ODialogHeaderComp
                    :title="indexSchedule==-1 ? 'Registrar' : 'Editar'"
                    @clickclose="ac_closeForm()"
                />
                <v-card-text class="pt-4 pb-0 pl-3 pr-3">
                    <v-form
                        v-on:submit.prevent="ac_sendToConfirmation()"
                        ref="formschedule"
                        v-model="valid"
                        lazy-validation
                    >
                        <MSelectComp
                            v-model="dataSchedule.day_es"
                            :items="listDays"
                            itemText="day_es"
                            itemValue="day_es"
                            label="Día"
                            @change="ac_chageDaySelect"
                            :rules="[v => !m_rule_emptyField(v) || 'El día es requerido']"
                        />
                        <MTextFieldComp
                            v-model="dataSchedule.schedule"
                            label="Horario"
                            :counter="20"
                            @clickenter="ac_sendToConfirmation()"
                            :rules="[
                                v => !m_rule_emptyField(v) || 'El horario es requerido',
                                v => (v+'').length<=20 || 'El horario no debe exeder los 20 dígitos'
                            ]"
                        />
                    </v-form>
                </v-card-text>
                <ODialogActionComp
                    actiontext="GUARDAR"
                    @click="ac_sendToConfirmation()"
                />
            </v-card>
        </v-dialog>
        <OLoaderOperationComp
            :dialog="dialogLoaderOperation"
            :message="smsLoaderOperation"
        />
        <OSmsErrorComp
            :dialog="dialogError"
            :smsError="smsError"
            :actionError="actionError"
            @click="ac_backError()"
        />
        <OSmsConfirmComp
            :dialog="dialogConfirm"
            :smsConfirm="smsConfirm"
            @clickyes="ac_performOperation()"
            @clickno="ac_backError()"
        />
        <OSmsActionComp
            @click="frameCorrect=false"
            :snakCorrecto="frameCorrect"
            :timeout="4000"
            :smsAction="smsAction"
        />
    </div>
</template>
<script>
import { User, Schedule } from '../../models'
import {
    MLineComp,
    MTextFieldComp,
    MSelectComp,
    MTitleNoData
} from '../../components/molecules'
import {
    OButtonAnimateHeaderComp,
    OButtonCloseSesionComp,
    OLoaderOperationComp,
    OSmsErrorComp,
    OLoaderDataComp,
    OButtonActionCardComp,
    ODialogHeaderComp,
    ODialogActionComp,
    OSmsConfirmComp,
    OSmsActionComp
} from '../../components/organisms'
import {
    menuCompMixin,
    dataGeneralComponetsMixin,
    responseServerMixin,
    saveUserMixin,
    rulesMixin
} from '../../mixins'
export default {
    mixins: [
        menuCompMixin,
        dataGeneralComponetsMixin,
        responseServerMixin,
        saveUserMixin,
        rulesMixin
    ],
    components: {
        MLineComp,
        MTextFieldComp,
        MSelectComp,
        OButtonAnimateHeaderComp,
        OButtonCloseSesionComp,
        OLoaderOperationComp,
        OSmsErrorComp,
        OLoaderDataComp,
        OButtonActionCardComp,
        ODialogHeaderComp,
        ODialogActionComp,
        OSmsConfirmComp,
        OSmsActionComp,
        MTitleNoData
    },
    data: () => ({
        dataUser: {},
        listDays: [
            {
                day_es: 'Lunes',
                day_en: 'Monday'
            },{
                day_es: 'Martes',
                day_en: 'Tuesday'
            },{
                day_es: 'Miercoles',
                day_en: 'Wednesday'
            },{
                day_es: 'Jueves',
                day_en: 'Thursday'
            },{
                day_es: 'Viernes',
                day_en: 'Friday'
            },{
                day_es: 'Sabado',
                day_en: 'Saturday'
            },{
                day_es: 'Domingo',
                day_en: 'Sunday'
            }
        ],
        scheduleList: [],
        dialogForm: false,
        dataSchedule: new Schedule(),
        indexSchedule: -1,
        idSchedule: -1,
        valid: true
    }),
    methods: {
        processError(error) {
            this.dialogLoaderOperation = false
            this.smsError = this.m_response_smsError(error)
            this.actionError = this.m_response_action(error)
            this.noAccess = this.m_response_autorization(error)
            this.dialogError = true
        },
        getScheduleList() {
            this.showLoaderData = true
            Schedule.getScheduleList().then(response => {
                this.showLoaderData = false
                this.scheduleList = response
            }).catch(error => {
                this.processError(error)
            })
        },
        validateForm() {
            var admisiondatos = true
            if (!this.$refs.formschedule.validate()) { admisiondatos = false }
            return admisiondatos
        },
        showSmsAction(sms) {
            this.smsAction = sms
            this.frameCorrect = true
            this.timeSucess = setTimeout(() => {
                this.frameCorrect = false
            }, 4000)
        },
        addSchedule() {
            Schedule.addSchedule(this.dataSchedule).then(response => {
                this.scheduleList.unshift(response)
                this.showSmsAction(['Registro exitoso', 'Se registró el día: ' + response.day_es])
                this.ac_closeForm()
                this.dialogLoaderOperation = false
            }).catch(error => {
                this.processError(error)
            })
        },
        updateSchedule() {
            Schedule.updateSchedule(this.dataSchedule).then(response => {
                this.scheduleList[this.indexSchedule] = response
                this.showSmsAction(['Actualización exitosa',
                    'Se actualizó el día: ' + response.day_es])
                this.ac_closeForm()
                this.dialogLoaderOperation = false
            }).catch(error => {
                this.processError(error)
            })
        },
        deleteSchedule() {
            Schedule.deleteSchedule(this.idSchedule).then(response => {
                this.scheduleList.splice(this.indexSchedule, 1)
                this.showSmsAction(['Eliminación exitosa',
                    'Se eliminó el día: ' + response.day_es])
                this.indexSchedule = -1
                this.idSchedule = -1
                this.dialogLoaderOperation = false
            }).catch(error => {
                this.processError(error)
            })
        },
        // actions HTML
        ac_closedSession() {
            this.smsLoaderOperation = 'Cerrando Sesión'
            this.dialogLoaderOperation = true
            User.closedSession().then(() => {
                this.dialogLoaderOperation = false
                localStorage.removeItem('sweetHome')
                this.$router.replace('/login')
            }).catch(() => {
                this.dialogLoaderOperation = false
                localStorage.removeItem('sweetHome')
                this.$router.replace('/login')
            })
        },
        ac_openForm(isNew, schedule, index) {
            this.indexSchedule = index
            if (!isNew) this.dataSchedule = Object.assign({}, schedule)
            this.dialogForm = true
        },
        ac_closeForm() {
            this.dialogForm = false
            this.$refs.formschedule.resetValidation()
            this.dataSchedule = new Schedule()
        },
        ac_sendToConfirmation() {
            this.dialogForm = false
            if (this.validateForm()) {
                const smsAction = this.indexSchedule === -1 ? 'registrar' : 'actualizar'
                this.smsConfirm = [`¿Está seguro de ${smsAction}`, `el día: ${this.dataSchedule.day_es}?`]
                this.dialogConfirm = true
            } else {
                this.smsError = ['Revise los datos marcados con rojo, porfavor']
                this.actionError = true
                this.dialogError = true
            }
        },
        ac_backError() {
            if (this.noAccess) {
                localStorage.removeItem('sweetHome')
                this.$router.replace('/login')
            } else {
                this.dialogConfirm = false
                this.dialogError = false
                if (this.idSchedule === -1) this.dialogForm = true
                else this.idSchedule = -1
            }
        },
        ac_performOperation() {
            this.dialogConfirm = false
            this.smsLoaderOperation = 'Realizando Operación'
            this.dialogLoaderOperation = true
            if (this.idSchedule !== -1) {
                this.deleteSchedule()
            } else {
                if (this.indexSchedule !== -1) this.updateSchedule()
                else this.addSchedule()
            }
        },
        ac_confirmDelete(schedule) {
            this.idSchedule = schedule.id
            this.indexSchedule = this.scheduleList.indexOf(schedule)
            this.smsConfirm = [`¿Está seguro de eliminar`, `el día: ${schedule.day_es} ?`]
            this.dialogConfirm = true
        },
        ac_myAccount() {
            this.$router.push({
                'name': 'account'
            })
        },
        ac_chageDaySelect(e) {
            var dayObject = this.listDays.find(element => element.day_es === e)
            this.dataSchedule.day_en = dayObject.day_en
        }
    },
    mounted() {
        if (!this.m_saveDataUser()) {
            this.$router.replace('/login')
        } else {
            this.dataUser = JSON.parse(localStorage.getItem('sweetHome'))
            this.getScheduleList()
        }
    }
}
</script>